import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardHeader,
  Container
} from '@material-ui/core'
import Alert from '../../components/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 400
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170',
    padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginBottom: '3vh'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faImage: {
    fontSize: 300,
    color: 'Dodgerblue'
  },
  nextButton: {
    background: '#fbb116',
    border: '0 none',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.175)',
    borderRadius: '5px',
    // color: '#ffffff',
    cursor: 'pointer',
    display: 'inline-block',
    // fontFamily: 'ProximaNovaSemibold',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '50px',
    padding: '0 23px',
    textDecoration: 'none',
    // width: 'auto',
    minWidth: '300px',
    textAlign: 'center',
    marginTop: 20
  }
}))

const Card = styled(MuiCard)(spacing)

const Divider = styled(MuiDivider)(spacing)

const Paper = styled(MuiPaper)(spacing)

export default function Banks() {
  const [state, setState] = useState({
    isLoading: false,
    banks: [],
    isError: false,
    errorMessage: ''
  })

  const getBanks = async () => {
    setState(state => ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    }))

    try {
      const banks = await _getBanks()
      setState(state => ({
        ...state,
        banks,
        isLoading: false
      }))
    } catch (e) {
      setState(state => ({
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: e.message
      }))
    }
  }

  useEffect(() => {
    getBanks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function _getBanks() {
    return await API.get('banks', '/banks')
  }

  function MaterialT(props) {
    const { banks } = props
    return (
      <div>
        {state.isError ? <Alert message={state.errorMessage} /> : null}
        <MaterialTable
          isLoading={state.isLoading}
          editable={{
            isEditable: () => true
          }}
          columns={[
            {
              title: 'Name',
              field: 'PK',
              render: rowData => rowData.PK.substring(5)
            },
            {
              title: 'Bank Code',
              field: 'bank_code'
            }
          ]}
          data={[...banks]}
          title="Banks"
          options={{
            search: false,
            paging: false
          }}
          detailPanel={rowData => {
            return (
              <>
                <Container
                  fixed
                  spacing={3}
                  direction="row"
                  className="container"
                  justifyContent="flex-start"
                >
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 20 }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} sm={6} xl={4} lg={4} md={4}>
                      <Paper elevation={3}>
                        <Card className={classes.card}>
                          <CardHeader title="Branch Info" />
                          <ul>
                            {rowData.locations.map(r => {
                              return (
                                <li>
                                  {r.name} {r.branch}
                                </li>
                              )
                            })}
                          </ul>
                        </Card>
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              </>
            )
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </div>
    )
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <MaterialT banks={state.banks} />
          <Divider my={6} />
        </Grid>
      </Grid>
    </div>
  )
}
