import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API, Auth } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'

import { Grid, Divider as MuiDivider } from '@material-ui/core'
import Alert from '../../components/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

const Divider = styled(MuiDivider)(spacing)

export default function AdminUsers(props) {
  const [state, setState] = useState({
    email: '',
    passowrd: '',
    users: [],
    isLoading: false,
    isError: false,
    errorMessage: ''
  })

  useEffect(() => {
    setState(state => ({
      ...state,
      isLoading: true,
      isError: false,
      errorMessage: ''
    }))
    async function onLoad() {
      if (!props.isAuthenticated) {
        return
      }

      try {
        const users = await getUsers()
        console.log(users)
        setState(state => ({
          ...state,
          users,
          isLoading: false
        }))
      } catch (e) {
        setState(state => ({
          ...state,
          isLoading: false,
          isError: true,
          errorMessage: e.message
        }))
      }
    }

    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getUsers() {
    return await API.get('users', `/users/${Auth.userPool.userPoolId}`)
  }

  async function createUser(data) {
    return await API.put('users', `/users/add`, {
      body: {
        name: data.name,
        password: 'Changeme$1',
        userPoolId: Auth.userPool.userPoolId
      }
    })
  }

  function MaterialT(props) {
    const { users } = props
    let userArray = []

    users.forEach(function(arrayItem) {
      let user = {}
      arrayItem.Attributes.forEach(function(attr) {
        if (attr.Name === 'email') {
          user.email = attr.Value
        } else if (attr.Name === 'given_name') {
          user.name = attr.Value
        }
      })
      userArray.push(user)
    })
    return (
      <div>
        {state.isError ? <Alert message={state.errorMessage} /> : null}

        <MaterialTable
          isLoading={state.isLoading}
          editable={{
            onRowAdd: newData => new Promise(resolve => createUser(newData))
          }}
          columns={[
            {
              title: 'Name',
              field: 'name'
            },
            {
              title: 'Email',
              field: 'email'
            }
          ]}
          data={[...userArray]}
          title="Admin Users"
          options={{
            search: false,
            paging: false
          }}
        />
      </div>
    )
  }
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          {/* <Loader type="ThreeDots" color="#00BFFF" height={70} width={70} /> */}
          <MaterialT users={state.users} />
          <Divider my={6} />
        </Grid>
      </Grid>
    </div>
  )
}
