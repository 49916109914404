import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import { format } from 'date-fns'
import { Grid, Divider as MuiDivider } from '@material-ui/core'
import Alert from '../../components/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

const Divider = styled(MuiDivider)(spacing)

function Leads(props) {
  const [leads, setLeads] = useState([])
  const [update, setUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errorRes, setErrorRes] = useState({
    error: false,
    message: ''
  })

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      setErrorRes({ error: false, message: '' })

      if (!props.isAuthenticated) {
        return
      }

      try {
        const data = await loadLeads()
        setLeads(data)
        setIsLoading(false)
      } catch (e) {
        setErrorRes({ error: true, message: e.message })
        setIsLoading(false)
      }
    }

    onLoad()
  }, [props.isAuthenticated, update])

  function updateLeads() {
    setUpdate(!update)
  }

  function loadLeads() {
    return API.get('leads', '/leads')
  }

  async function handleSubmit(newData) {
    setErrorRes({ error: false, message: '' })
    try {
      await createLead(newData)
      updateLeads()
    } catch (e) {
      setIsLoading(false)
      setErrorRes({ error: true, message: e.message })
    }
  }

  function createLead(lead) {
    return API.post('leads', '/leads', {
      body: lead
    })
  }

  function MaterialT(props) {
    const { leads } = props
    return (
      <div>
        {errorRes.error ? <Alert message={errorRes.message} /> : null}
        <MaterialTable
          isLoading={isLoading}
          editable={{
            onRowAdd: newData => new Promise(resolve => handleSubmit(newData))
          }}
          columns={[
            {
              title: 'Name',
              field: 'name'
            },
            {
              title: 'Email',
              field: 'email'
            },
            {
              title: 'Message',
              field: 'message'
            },
            {
              title: 'Date',
              field: 'createdAt',
              render: rowData =>
                format(new Date(rowData.createdAt), 'MM/dd/yyyy')
            }
          ]}
          data={[...leads]}
          title="Cloud Solar Leads"
          options={{
            search: false,
            paging: false
          }}
        />
      </div>
    )
  }
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <MaterialT leads={leads} />
          <Divider my={6} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Leads
