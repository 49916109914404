export const PARISH_PROVINCE_STATE = [
  'Christ Church',
  'St. Andrew',
  'St. George',
  'St. James',
  'St. John',
  'St. Joseph',
  'St. Lucy',
  'St. Michael',
  'St. Peter',
  'St. Philip',
  'St. Thomas'
]

export const BANKS = [
  {
    name: 'Bank of Nova Scotia',
    bank_code: '002',
    locations: [
      { name: 'Independence Square', branch: '21295' },
      { name: 'Warrens', branch: '36285' },
      { name: 'Broad Street', branch: '40055' },
      { name: 'Wildey', branch: '62935' },
      { name: 'Holetown', branch: '63685' },
      { name: 'Haggatt Hall', branch: '66555' },
      { name: 'Worthing', branch: '70565' },
      { name: 'Black Rock', branch: '80655' }
    ]
  },
  {
    name: 'Royal Bank of Canada',
    bank_code: '003',
    locations: [
      { name: 'St. Lawrence', branch: '05605' },
      { name: 'Private Banking', branch: '06465' },
      { name: 'Sunset Crest', branch: '09425' },
      { name: 'Broad Street', branch: '09435' },
      { name: 'Hastings (Lanterns)', branch: '09545' },
      { name: 'Speightstown', branch: '09565' },
      { name: 'Chelston Park', branch: '09785' },
      { name: 'Six Roads', branch: '00004' },
      { name: 'Bridgetown (RBTT)', branch: '00001' },
      { name: 'Hastings (RBTT)', branch: '00003' },
      { name: 'Sunset Crest (RBTT)', branch: '00003' }
    ]
  },
  {
    name: 'First Caribbean',
    bank_code: '010',
    locations: [
      { name: 'Fontabelle', branch: '08006' },
      { name: 'Oistins', branch: '09286' },
      { name: 'Warrens', branch: '09326' },
      { name: 'Sheraton', branch: '09586' },
      { name: 'Wildey', branch: '09127' },
      { name: 'Bridgetown', branch: '09606' },
      { name: 'Rendezvous', branch: '09616' },
      { name: 'Speightstown', branch: '09636' },
      { name: 'Holetown', branch: '09646' }
    ]
  },
  {
    name: 'Republic Bank',
    bank_code: '006',
    locations: [
      { name: 'Fairchild Street', branch: '00001' },
      { name: 'Broad Street', branch: '00002' },
      { name: 'Airport', branch: '00004' },
      { name: 'Wildey', branch: '00005' },
      { name: 'Speightstown', branch: '00006' },
      { name: 'Emerald City', branch: '00009' },
      { name: 'Warrens', branch: '01010' },
      { name: 'Worthing', branch: '00014' },
      { name: 'Holetown (Limegrove)', branch: '00017' }
    ]
  },
  {
    name: 'First Citizens',
    bank_code: '009',
    locations: [
      { name: 'Broad Street', branch: '00001' },
      { name: 'Collymore Rock', branch: '00002' },
      { name: "JB's - Sargeant Village", branch: '00004' },
      { name: 'Big B - Rendezvous', branch: '00003' },
      { name: 'Summerly, Worthing', branch: '00007' },
      { name: 'The Walk, Welches', branch: '00008' }
    ]
  },
  {
    name: 'Credit Union',
    bank_code: '999',
    locations: [
      { name: 'BWU Credit Union', branch: '01002' },
      { name: 'Barbados Public Workers Credit Union', branch: '01001' },
      { name: 'COB Credit Union', branch: '01003' }
    ]
  }
]
