import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { connect } from 'react-redux'

import {
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from '@material-ui/core'

import { Menu as MenuIcon } from '@material-ui/icons'

import { Power } from 'react-feather'

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

function UserMenu(props) {
  const [anchorMenu, setanchorMenu] = useState(null)

  function toggleMenu(event) {
    setanchorMenu(event.currentTarget)
  }

  function closeMenu() {
    setanchorMenu(null)
  }

  const open = Boolean(anchorMenu)

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            closeMenu()
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.handleLogout()
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function Header(props) {
  const { handleLogout, onDrawerToggle } = props
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <UserMenu handleLogout={handleLogout} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default connect()(withTheme(Header))
