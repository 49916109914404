import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const stepOneValidation = () =>
  yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .required('First Name is required.')
        .min(2, 'First Name must be at least two letters.'),
      lastName: yup
        .string()
        .required('Last Name is required.')
        .min(2, 'First Name must be at least two letters.'),
      email: yup
        .string()
        .required('Email is required.')
        .email('Email is not valid.'),
      password: yup
        .string()
        .required('Password is required.')
        .matches(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/,
          'Password must contain 8 characters, one Uppercase, one Lowercase, one Number and one special case Character'
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Confirm Passwords must match.')
    })
  )

export const stepTwoValidation = () =>
  yupResolver(
    yup.object().shape({
      address: yup.string().required('Address is required.'),
      city: yup.string().required('City is required.'),
      parishProvinceState: yup
        .string()
        .required('Parish/Province/State is required.'),
      zipPostal: yup.string().required('Postal/Zip Code is required.'),
      bankAccountType: yup
        .string()
        .required('Please choose Bank Account type.'),
      bankAccountName: yup
        .string()
        .required("Bank Account Holder's Name is required."),
      bankName: yup.string().required('Bank Name is required.'),
      bankLocation: yup.string().required('Bank Location is required.'),
      bankBranch: yup.string().required('Bank Branch is required.')
    })
  )
