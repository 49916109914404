import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Divider as MuiDivider } from '@material-ui/core'
import Loader from 'react-loader-spinner'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

const Divider = styled(MuiDivider)(spacing)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

function getSteps() {
  return ['Basic Info', 'Address, Docs, Bank Info & Select Panels']
}

function getStepContent({ activeStep, handleBack, handleNext, steps }) {
  switch (activeStep) {
    case 0:
      return <StepOne handleNext={handleNext} />
    case 1:
      return <StepTwo handleNext={handleNext} handleBack={handleBack} />
    default:
      return 'Unknown stepIndex'
  }
}

export default function NewClient() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Typography variant="h3" display="inline">
              Add a client
            </Typography>
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              visible={false}
            />
            <Divider my={6} />
          </Grid>
        </Grid>
        <Grid container spacing={10} style={{ marginTop: 20 }}>
          <Grid item xl={12}>
            <Paper className={classes.paper}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className={classes.instructions}>
                {getStepContent({
                  activeStep,
                  handleBack,
                  handleNext,
                  steps
                })}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
