import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { find, orderBy } from 'lodash'

import {
  Grid,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography
} from '@material-ui/core'
import Alert from '../../components/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

const Divider = styled(MuiDivider)(spacing)

const Paper = styled(MuiPaper)(spacing)

function Rates(props) {
  const classes = useStyles()

  const [rates, setRates] = useState([])
  const [update, setUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // const [investmentAmount, setInvestmentAmount] = useState()
  const [fitRate, setFitRate] = useState()
  const [maxCloudPrice, setMaxCloudPrice] = useState()
  const [dollarReturn, setDollarReturn] = useState()
  const [percentageReturn, setPercentageReturn] = useState()
  const [pricePerKw, setPricePerKw] = useState()
  // const [panels, setPanels] = useState()
  // const [ac, setAc] = useState()
  // const [dc, setDc] = useState()
  const [values, setValues] = useState({
    maxCloudPrice: 0,
    fitRate: 0,
    pricePerKw: 0
  })

  const [errorRes, setErrorRes] = useState({
    error: false,
    message: ''
  })

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      setErrorRes({ error: false, message: '' })

      if (!props.isAuthenticated) {
        return
      }

      try {
        const data = await loadRates()
        setRates(data)
      } catch (e) {
        setErrorRes({ error: true, message: e.message })
      }

      setIsLoading(false)
    }

    onLoad()
  }, [props.isAuthenticated, update])

  function updateRates() {
    setUpdate(!update)
  }

  function loadRates() {
    return API.get('rates', '/rates')
  }

  async function handleSubmit(newData) {
    setErrorRes({ error: false, message: '' })
    try {
      await createRate(newData)
      updateRates()
    } catch (e) {
      setIsLoading(false)
      setErrorRes({ error: true, message: e.message })
    }
  }

  function createRate(rate) {
    return API.post('rates', '/rates', {
      body: rate
    })
  }

  function calculateReturn(e) {
    let ppk = 0
    let fr = 0
    let mcp = 0
    let investmentAmount = e.target.value
    setValues(values => ({ ...values, maxCloudPrice: investmentAmount }))
    let sortedRates = orderBy(rates, ['maxCloudPrice'], ['asc'])
    find(sortedRates, function(rate) {
      //This if is so users with an existing contract can buy a contract for less than 2000
      // if (investmentAmount < 2000 && parseInt(rate.maxCloudPrice) === 2000) {
      //   return rate.maxCloudPrice
      // }
      if (rate.maxCloudPrice <= investmentAmount) {
        ppk = rate.pricePerKw
        fr = rate.fitRate
        mcp = rate.maxCloudPrice
      }
    })

    setMaxCloudPrice(mcp)
    setFitRate(fr)
    setPricePerKw(ppk)
    setDollarReturn((investmentAmount / ppk) * 1500 * fr * (1 - 0.2))
    setPercentageReturn(
      ((investmentAmount / ppk) * 1500 * fr * (1 - 0.2)) / investmentAmount
    )
  }

  function MaterialT(props) {
    const { rates } = props
    return (
      <div>
        <MaterialTable
          isLoading={isLoading}
          editable={{
            isEditable: () => true,
            // isDeletable: () => true,
            onRowAdd: newData => new Promise(resolve => handleSubmit(newData)),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                /* const data = this.state.data;
              const index = data.indexOf(oldData);
              data[index] = newData;
              this.setState({ data }, () => resolve()); */
              })
            // onRowDelete: oldData =>
            //     new Promise((resolve, reject) => {
            //         /* let data = this.state.data;
            //     const index = data.indexOf(oldData);
            //     data.splice(index, 1);
            //     this.setState({ data }, () => resolve()); */
            //     })
          }}
          columns={[
            {
              title: 'Max Investment',
              field: 'maxCloudPrice',
              type: 'numeric',
              defaultSort: 'asc',
              render: rowData => numeral(rowData.maxCloudPrice).format('0,0')
            },
            {
              title: 'Fit Rate',
              field: 'fitRate',
              type: 'numeric',
              render: rowData => numeral(rowData.fitRate).format('0.0[0000]')
            },
            {
              title: 'Price Per Kw',
              field: 'pricePerKw',
              type: 'numeric',
              render: rowData => numeral(rowData.pricePerKw).format('0,0')
            }
          ]}
          data={[...rates]}
          title="Cloud Solar Rates Table"
          options={{
            search: false,
            paging: false
          }}
        />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            {errorRes.error ? <Alert message={errorRes.message} /> : null}
            <Typography variant="h3" display="inline">
              Rates
              {/* <RateModal updateRates={updateRates} /> */}
            </Typography>
            <Divider my={6} />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xl={6}>
            <MaterialT rates={rates} />
          </Grid>
          <Grid item xl={6}>
            <Paper className={classes.paper}>
              <Grid item xl={12}>
                <Typography variant="h5" align="left">
                  Investment Calculator
                </Typography>
              </Grid>
              <TextField
                autoFocus
                margin="dense"
                name="investmentAmount"
                label="Investment Amount"
                value={values.maxCloudPrice}
                onChange={calculateReturn}
                fullWidth
                type="number"
                InputProps={{
                  min: 10000,
                  // inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">$ </InputAdornment>
                  )
                }}
                // error={priceError}
                // helperText={priceErrorText}
              />
              <TextField
                margin="dense"
                name="maxCloudPrice"
                label="Max Cloud Price"
                value={numeral(maxCloudPrice).format('$ 0,0')}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                label="Fit Rate"
                value={numeral(fitRate).format('0.0[0000]')}
                fullWidth
                type="number"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                label="Price Per Kw"
                value={numeral(pricePerKw).format('$ 0,0')}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                label="Annual $ return"
                name="annualDollarReturn"
                value={numeral(dollarReturn).format('$ 0,0.00')}
                fullWidth
                // type="number"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                name="annualPercentageReturn"
                label="Annual % return"
                value={numeral(percentageReturn).format('0.00%')}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled
              />
              <TextField
                margin="dense"
                name="panels"
                label="kWp"
                value={numeral(values.maxCloudPrice / pricePerKw).format(
                  '0.00'
                )}
                fullWidth
                type="number"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              {/* <TextField
                margin="dense"
                name="ac"
                label="AC"
                value={ac}
                fullWidth
                type="number"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                margin="dense"
                name="dc"
                label="DC"
                value={dc}
                fullWidth
                type="number"
                disabled
                InputLabelProps={{ shrink: true }} 
              />*/}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Rates
