import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import { format } from 'date-fns'
import { Grid, Divider as MuiDivider } from '@material-ui/core'
import Alert from '../../components/Alert'
import { KeyboardDatePicker } from '@material-ui/pickers'
import parseISO from 'date-fns/parseISO'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

const Divider = styled(MuiDivider)(spacing)

function Plants() {
  const [plants, setPlants] = useState([])
  const [update, setUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [kWp, setKwp] = useState(0)
  const [errorRes, setErrorRes] = useState({
    error: false,
    message: ''
  })

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      setErrorRes({ error: false, message: '' })

      try {
        const data = await loadPlants()
        setPlants(data)
        let kwp = 0
        data.forEach(p => (kwp += parseFloat(p.kWp)))
        setKwp(kwp)
        setIsLoading(false)
      } catch (e) {
        setErrorRes({ error: true, message: e.message })
        setIsLoading(false)
      }
    }

    onLoad()
  }, [update])

  async function loadPlants() {
    return await API.get('plants', '/plants')
  }

  async function updatePlant(data) {
    const updateData = { ...data.plant, plantData: data.newData }
    return await API.put('plants', `/plants/update/${data.plant.plantId}`, {
      body: updateData
    })
  }

  async function addNewPlant(newData) {
    setErrorRes({ error: false, message: '' })
    try {
      await createPlant(newData)
      setUpdate(!update)
    } catch (e) {
      setErrorRes({ error: true, message: e.message })
    }
  }

  async function addUpdatePlantData(newData) {
    setErrorRes({ error: false, message: '' })
    try {
      await API.put('plants', `/plants/addUpdatePlantData/${newData.plantId}`, {
        body: newData.data
      })
      setUpdate(!update)
    } catch (e) {
      setErrorRes({ error: true, message: e.message })
    }
  }

  async function updatePlantData(newData) {
    setErrorRes({ error: false, message: '' })
    try {
      await API.put('plants', `/plants/update/${newData.plantId}`, {
        body: newData.data
      })
      setUpdate(!update)
    } catch (e) {
      setIsLoading(false)
      setErrorRes({ error: true, message: e.message })
    }
  }

  async function createPlant(plant) {
    return await API.post('plants', '/plants', {
      body: plant
    })
  }

  function MaterialT(props) {
    const { plants } = props
    return (
      <div>
        {errorRes.error ? <Alert message={errorRes.message} /> : null}
        <MaterialTable
          isLoading={isLoading}
          // editable={{
          //   isEditable: () => true,
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve, reject) => {
          //       setTimeout(() => {
          //         const data = [...plants]
          //         const index = data.indexOf(oldData)
          //         data[index] = newData
          //         setPlants([...data])
          //         resolve()
          //       }, 1000)
          //     }),
          //   onRowAdd: newData => addNewPlant(newData)
          // }}
          columns={[
            {
              title: 'Name',
              field: 'plantName',
              defaultSort: 'asc'
            },
            {
              title: 'Date Commisioned',
              field: 'dateCommissioned',
              type: 'date',
              customSort: (a, b) =>
                new Date(a.dateCommissioned) - new Date(b.dateCommissioned),
              render: rowData =>
                format(new Date(rowData.dateCommissioned), 'MMM yyyy')
            },
            {
              title: 'kWp',
              field: 'kWp',
              type: 'numeric'
            }
          ]}
          data={[...plants]}
          detailPanel={plant => {
            return (
              <MaterialTable
                editable={{
                  onRowAdd: newPlantData =>
                    new Promise((resolve, reject) => {
                      plant.plantData.forEach(element => {
                        delete element.tableData
                      })
                      const shortDatePeriod = newPlantData.period.toLocaleDateString(
                        'en-US'
                      )
                      const plantId = plant.plantId
                      const updatedPlant = {
                        plantId: plantId,
                        data: {
                          plantData: {
                            period: shortDatePeriod,
                            kWh: newPlantData.kWh
                          }
                        }
                      }
                      addUpdatePlantData(updatedPlant).then(result => {
                        setUpdate(!update)
                      })
                    }),
                  onRowUpdate: updatedPlantData =>
                    new Promise((resolve, reject) => {
                      const plantId = plant.plantId
                      const shortDatePeriod = updatedPlantData.period.toLocaleDateString(
                        'en-US'
                      )
                      const updatedPlant = {
                        plantId: plantId,
                        data: {
                          plantData: {
                            periodId: updatedPlantData.periodId,
                            period: shortDatePeriod,
                            kWh: updatedPlantData.kWh
                          }
                        }
                      }
                      addUpdatePlantData(updatedPlant).then(result => {
                        // setUpdate(!update)
                        resolve()
                      })
                    })
                }}
                columns={[
                  {
                    title: 'Period',
                    field: 'period',
                    type: 'date',
                    defaultSort: 'asc',
                    customSort: (a, b) =>
                      new Date(b.period) - new Date(a.period),
                    editComponent: ({ value, onChange }) => (
                      <KeyboardDatePicker
                        autoOk
                        variant="dialog"
                        invalidDateMessage={'Period is required'}
                        format="MM/dd/yyyy"
                        label="Select 1st of Month"
                        value={value}
                        onChange={onChange}
                        name="period"
                      />
                    )
                  },
                  {
                    title: 'kWh',
                    field: 'kWh',
                    type: 'numeric',
                    validate: row =>
                      isNaN(row.kWh) ? 'kWh value is required' : true
                  }
                ]}
                data={[...plant.plantData]}
                title={`${plant.plantName} Data`}
                options={{ actionsColumnIndex: -1 }}
              />
            )
          }}
          title={`Cloud Solar Plants - ${kWp.toFixed(2)} kWp`}
          options={{
            search: false,
            paging: false,
            actionsColumnIndex: -1
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </div>
    )
  }
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <MaterialT plants={plants} />
          <Divider my={6} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Plants
