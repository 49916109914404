import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import {
  Button,
  Grid,
  TextField,
  Paper as MuiPaper,
  Typography
} from '@material-ui/core'
import { format, isBefore, isSameMonth, parse, parseISO } from 'date-fns'
import numeral from 'numeral'
import config from '../../config'
import { CSVLink } from 'react-csv'
import Alert from '../../components/Alert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 20,
    marginBottom: 20
    // minHeight: 600
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1)
    // minWidth: 200
  }
}))

const Paper = styled(MuiPaper)(spacing)

export default function Returns() {
  const poolId = config.cognito.CLIENT_USER_POOL_ID
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState({
    testFields: {
      investmentAmount: 2600,
      ppk: 4000,
      monthlyPower: 1200,
      fitRate: 0.4275,
      managementFee: 20,
      return: ''
    },
    fields: {
      monthlyPower: 1200,
      managementFee: 20,
      return: ''
    },
    currentReturn: [],
    contracts: [],
    transactions: [],
    returns: [],
    auditComplete: false,
    currentReturnPeriod: '',
    daysInReturn: 0,
    rid: '',
    update: true,
    transactionCount: 0,
    plantData: [],
    contractsLength: 0,
    users: [],
    returnTotal: 0,
    userUpdateData: []
  })

  const [errorRes, setErrorRes] = useState({
    error: false,
    message: ''
  })

  const updateFields = e => {
    setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [e.target.name]: e.target.value
      }
    }))
  }

  async function calculateReturns() {
    let currentReturnData = []
    let data = []
    let total = 0
    let count = 0
    let userUpdateData = []

    state.contracts.forEach(function(contract) {
      let returnAmount =
        contract.panels *
        0.26 *
        0.4275 *
        state.fields.monthlyPower *
        (1 - state.fields.managementFee / 100)

      let user = state.users.find(u => u.sub === contract.userId)
      if (user !== undefined) {
        let currentCreditsTotal = parseFloat(user['custom:creditsTotal'])
        let updatedUser = {}
        let newCreditsTotal = 0
        //For users with multiple contracts
        //If the user already has a creditsTotal append to it
        let existingUser = userUpdateData.find(u => u.Username === user.sub)
        if (existingUser !== undefined) {
          newCreditsTotal = (
            parseFloat(existingUser.UserAttributes[0].Value) +
            parseFloat(returnAmount)
          )
            .toFixed(2)
            .toString()
          existingUser.UserAttributes[0].Value = newCreditsTotal
        } else {
          newCreditsTotal = (currentCreditsTotal + parseFloat(returnAmount))
            .toFixed(2)
            .toString()
          updatedUser = {
            UserAttributes: [
              {
                Name: 'custom:creditsTotal',
                Value: newCreditsTotal
              }
            ],
            UserPoolId: config.cognito.CLIENT_USER_POOL_ID,
            Username: user.sub
          }
          userUpdateData.push(updatedUser)
        }
        total += returnAmount
        count++
        let bankData = {
          name: user.fullName,
          bank_code: user['custom:bankCode'].toString(),
          branch_code: user['custom:bankBranch'].toString(),
          account_number: user['custom:bankAccountNumber'].toString(),
          amount: returnAmount.toFixed(2),
          account_type: 9,
          transaction_code: 77,
          value_date: format(new Date(), 'dMyy'),
          currency_code: '00',
          originator_account_number: '1001199826',
          reference: contract.contractId,
          narrative: 'SolarPayment',
          currentCreditsTotal,
          sub: user.sub,
          email: user.email
        }
        data.push(bankData)
      }
    })
    currentReturnData.push({
      PK: `RPER#${state.currentReturnPeriod}`,
      SK: `RPER#${state.currentReturnPeriod}`,
      rid: state.rid,
      bankData: data,
      auditPosted: true,
      transactionCount: count,
      returnTotal: parseFloat(total.toFixed(2))
    })
    try {
      await API.put('returns', '/returns', {
        body: currentReturnData
      })
      setState(state => ({
        ...state,
        userUpdateData,
        update: !state.update
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const getUsers = async () => {
    try {
      const userArr = await API.get('users', `/users/${poolId}`)
      setState(state => ({
        ...state,
        users: userArr
      }))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      setErrorRes({ error: false, message: '' })

      try {
        const returnData = await loadReturns()
        const contractData = await loadContracts()
        const plants = await loadPlants()
        const currentReturnData = _getCurrentReturnData(returnData)
        await getUsers()
        const activeContracts = contractData.filter(c => {
          const from = parseISO(c.activeDate, 'MM/dd/yyyy', new Date())
          const to = parse(
            currentReturnData.currentReturnPeriod,
            'MM/dd/yyyy',
            new Date()
          )
          if (
            (!c.active ||
              c.activeDate === '' ||
              c.activeDate === null ||
              !isBefore(new Date(from), new Date(to))) &&
            !isSameMonth(new Date(from), new Date(to))
          ) {
            return false
          }
          return true
        })
        let kwhTotal = 0
        let kWp = 0
        const periodPlantData = []
        plants.forEach(plant => {
          plant.plantData.forEach(pd => {
            if (
              format(new Date(pd.period), 'MM/dd/yyyy') ===
              format(
                new Date(currentReturnData.currentReturnPeriod),
                'MM/dd/yyyy'
              )
            ) {
              pd.plantName = plant.plantName
              pd.kWp = plant.kWp
              pd.monthlyPower = pd.kWh
              periodPlantData.push(pd)
            }
          })
        })
        periodPlantData.forEach(ppd => {
          for (let k in ppd) {
            if (k === 'monthlyPower') kwhTotal += ppd[k]
            if (k === 'kWp') kWp += ppd[k]
          }
        })
        periodPlantData.push({
          plantName: 'Totals',
          kWh: getPlantDataTotals(periodPlantData, 'kWh'),
          kWp: getPlantDataTotals(periodPlantData, 'kWp'),
          monthlyPower: getPlantDataTotals(periodPlantData, 'monthlyPower')
        })
        setState(state => ({
          ...state,
          contracts: activeContracts,
          plantData: periodPlantData,
          returns: returnData,
          currentReturn: currentReturnData.currentReturn,
          currentReturnPeriod: currentReturnData.currentReturnPeriod,
          rid: currentReturnData.currentReturnId,
          update: false,
          fields: {
            ...state.fields,
            monthlyPower: numeral(kwhTotal / kWp).format('000.00')
          }
        }))
      } catch (e) {
        setErrorRes({ error: true, message: e.message })
      }
      setIsLoading(false)
    }
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.update])

  async function loadContracts() {
    return await API.get('contracts', '/contracts/all')
  }

  async function loadReturns() {
    return await API.get('returns', '/returns')
  }

  async function loadPlants() {
    return await API.get('plants', '/plants')
  }

  function _getCurrentReturnData(returns) {
    if (returns.length > 0) {
      //Only grab periods that aren't complete
      const dates = returns
        .filter(r => r.Type === 'Return' && !r.returnCompleted)
        .map(r => r.PK.substring(5))
      //Get the earliest incomplete date
      let date = dates.reduce(function(c, n) {
        return Date.parse(c) < Date.parse(n) ? c : n
      })
      //Get the return id of the earliest period
      const r = returns.find(r => r.PK.substring(5) === date)

      return {
        currentReturn: r === undefined ? [] : [r],
        currentReturnPeriod: date,
        currentReturnId: r.rid.toString()
      }
    } else {
      return {
        currentReturnPeriod: '5/1/2016',
        currentReturnId: '1'
      }
    }
  }

  async function completeReturn(data) {
    try {
      await API.put('returns', `/returns/complete`, {
        body: {
          PK: data.PK,
          SK: data.SK,
          auditCompleted: true,
          returnCompleted: true,
          bankData: data.bankData,
          transactionCount: data.transactionCount,
          returnTotal: data.returnTotal,
          dateCompleted: format(new Date(), 'MM/dd/yyyy'),
          completedBy: 'Anthony DeFreitas'
        }
      })

      const waitFor = ms => new Promise(r => setTimeout(r, ms))
      const asyncForEach = async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array)
        }
      }

      const start = async () => {
        const data = state.userUpdateData
        await asyncForEach(data, async u => {
          console.log(u)
          await waitFor(50)
          API.patch('users', `/users/adminUpdate`, {
            body: u
          })
        })
      }

      start()

      await sendEmails(data.bankData)

      setState(state => ({
        ...state,
        update: !state.update
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const sendEmails = async data => {
    for (let user of data) {
      let emailData = {
        email: user.email,
        name: user.name.substring(0, user.name.indexOf(' ')),
        amount: user.amount,
        type: 'creditsPosted'
      }
      await sendEmail(emailData)
    }
  }

  const getPlantDataTotals = (data, key) => {
    return data.reduce((sum, p) => {
      for (let k in p) {
        if (key === k) return sum + p[k]
      }
      return sum
    }, 0)
  }

  async function sendEmail(data) {
    return await API.post('email', '/email', {
      body: data
    })
  }

  function generateBankData(data) {
    let bankData = []
    data.forEach(user => {
      const paymentInfo = {
        name: user.name,
        bank_code: user.bank_code,
        branch_code: user.branch_code,
        account_number: user.account_number,
        amount: numeral(user.amount).format('10000.00'),
        account_type: 9,
        transaction_code: '77',
        value_date: format(new Date(), 'ddMMyy'),
        currency_code: '00',
        originator_account_number: '1001199826',
        narrative: 'SolarPurchase'
      }
      bankData.push(paymentInfo)
    })
    return bankData
  }

  function AuditTable(props) {
    const { currentReturn } = props
    return (
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'Period',
            field: 'PK',
            render: rowData => rowData.PK.substring(5)
          },
          {
            title: '# of Payments',
            field: 'transactionCount'
          },
          {
            title: 'Payout Total',
            field: 'returnTotal',
            render: rowData => parseFloat(rowData.returnTotal)
          },
          {
            title: 'Bank CSV',
            field: 'bankData',
            render: rowData => (
              <CSVLink
                enclosingCharacter={``}
                data={
                  rowData.bankData === undefined
                    ? [[]]
                    : generateBankData(rowData.bankData)
                }
                filename={`payments_for_return_period: ${rowData.PK.substring(
                  5
                )}.csv`}
              >
                Download
              </CSVLink>
            )
          },
          {
            title: 'Created',
            field: 'gend',
            render: rowData => format(new Date(rowData.gend), 'MM/dd/yyyy')
          },
          {
            title: 'Completed Date',
            field: 'completedDate',
            render: rowData =>
              rowData.completedDate === undefined ||
              rowData.completedDate === '' ||
              rowData.completedDate === null
                ? 'Not completed'
                : format(new Date(rowData.completedDate), 'MM/dd/yyyy')
          }
        ]}
        data={[...currentReturn]}
        title={`Return data to be audited for the current return period starting ${state.currentReturnPeriod}`}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          actionsColumnIndex: -1
        }}
        actions={[
          rowData => ({
            icon: 'done_outline',
            tooltip: rowData.completed ? 'Completed' : 'Complete',
            onClick: (event, rowData) => completeReturn(rowData),
            disabled: rowData.completed
          })
        ]}
        detailPanel={rowData => {
          return (
            <>
              <MaterialTable
                isLoading={isLoading}
                columns={[
                  {
                    title: 'Name',
                    field: 'name'
                  },
                  {
                    title: 'Amount',
                    field: 'amount'
                  }
                ]}
                title={`Payment details`}
                options={{
                  search: false,
                  paging: false,
                  exportButton: true
                }}
                data={[...rowData.bankData]}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
              />
            </>
          )
        }}
      />
    )
  }

  function ContractsTable(props) {
    const { contracts } = props
    return (
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string'
            // render: rowData => numeral(rowData.userId).format('0')
          },
          {
            title: 'Active',
            field: 'active',
            type: 'boolean'
            // render: rowData => numeral(rowData.returnId).format('0.0000')
          },
          {
            title: 'Active Date',
            field: 'activeDate',
            type: 'string',
            defaultSort: 'asc',
            render: rowData =>
              format(new Date(rowData.activeDate), 'MM/dd/yyyy')
          },
          {
            title: 'Amount',
            field: 'amount',
            type: 'string',
            defaultSort: 'asc',
            render: rowData => numeral(rowData.amount).format('0,0')
          }
        ]}
        data={[...contracts]}
        title={`Active contracts for the period starting ${state.currentReturnPeriod}`}
        options={{
          search: false,
          paging: false,
          exportButton: true
        }}
      />
    )
  }

  function PlantDataTable(props) {
    const { plantData } = props
    return (
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'Plant',
            field: 'plantName',
            type: 'string'
            // defaultSort: 'asc'
          },
          {
            title: 'kWh',
            field: 'kWh',
            type: 'numeric',
            render: rowData => numeral(rowData.kWh).format('0,0.00')
          },
          {
            title: 'kWp',
            field: 'kWp',
            type: 'numeric',
            render: rowData => numeral(rowData.kWp).format('0,0.00')
          },
          {
            title: 'Monthly Power',
            field: 'monthlyPower',
            type: 'numeric',
            render: rowData => numeral(rowData.monthlyPower).format('0,0.00')
          }
        ]}
        data={[...plantData]}
        title={`Plant Data for the period starting ${state.currentReturnPeriod}`}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          cellStyle: {
            width: '50%'
          }
        }}
      />
    )
  }
  const classes = useStyles()
  const disableFields =
    state.plantData.length > 1 && state.contracts.length > 0 ? false : true
  // const disableAudit = state.currentReturn.length > 0 ? false : true
  // const disableProcess =
  //   state.plantData.length > 1 &&
  //   state.contracts.length > 0 &&
  //   state.auditComplete
  //     ? false
  //     : true
  return (
    <div className={classes.root}>
      {errorRes.error ? <Alert message={errorRes.message} /> : null}
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Grid item lg={12} xl={12} style={{ margin: 20 }}>
              <Typography variant="subtitle2" align="left" gutterBottom>
                Calculate Returns
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} xl={12} style={{ margin: 20 }}>
              <TextField
                disabled
                label="Period"
                value={state.currentReturnPeriod}
                variant="outlined"
                style={{ margin: 20 }}
              ></TextField>
              <TextField
                disabled={disableFields}
                name="monthlyPower"
                label="Monthly Power"
                value={state.fields.monthlyPower}
                onChange={updateFields}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ margin: 20 }}
              />
              <TextField
                disabled={disableFields}
                name="managementFee"
                label="Management Fee"
                onChange={updateFields}
                value={state.fields.managementFee}
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={12} style={{ margin: 20 }}>
              <Button
                disabled={disableFields}
                variant="contained"
                color="primary"
                onClick={() => calculateReturns()}
              >
                Save Return Data for Audit
              </Button>
            </Grid>
            {/* <Grid item xs={2} lg={2} xl={2} style={{ margin: 20 }}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disableAudit}
                    checked={state.auditComplete}
                    onChange={handleChange}
                    name="auditComplete"
                  />
                }
                label="Audit Complete"
              />
            </Grid> */}
            {/* <Grid item xs={2} lg={2} xl={2} style={{ margin: 20 }}>
              <Button
                disabled={disableProcess}
                variant="contained"
                color="primary"
                onClick={postTransactions}
              >
                Post Transactions
              </Button>
            </Grid> */}
            <Typography variant="body1" gutterBottom>
              {!state.plantData.length > 1
                ? 'There is no Plant Data entered for the current period.'
                : ''}
              <br />
              {!state.contracts.length > 0
                ? 'There are no active contracts for the current period'
                : `There are ${state.contracts.length} contracts to be processed for the current period.`}
            </Typography>
          </Paper>
        </Grid>
        <Grid item lg={12} xl={12} style={{ marginTop: 20 }}>
          <AuditTable
            currentReturn={
              state.currentReturn[0] === undefined ||
              !state.currentReturn[0].auditPosted
                ? []
                : state.currentReturn
            }
          />
        </Grid>
        <Grid item lg={12} xl={12} style={{ marginTop: 20 }}>
          <ContractsTable contracts={state.contracts} />
        </Grid>

        {/* <Grid
          item
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        > */}
        <Grid item lg={12} xl={12}>
          <Grid item lg={12} xl={12} style={{ marginTop: 20 }}>
            <PlantDataTable plantData={state.plantData} />
          </Grid>
        </Grid>
        {/* </Grid> */}
      </Grid>
    </div>
  )
}
