import React from 'react'
import { connect } from 'react-redux'

import DateFnsUtils from '@date-io/date-fns'
import { enUS } from 'date-fns/locale'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import maTheme from './theme'
import Main from './Main'

function App({ theme }) {
  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enUS}>
        <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
          <ThemeProvider theme={maTheme[theme.currentTheme]}>
            <Main />
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  )
}

export default connect(store => ({ theme: store.themeReducer }))(App)
