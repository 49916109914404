import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Routes from './routes/Routes'

function Main(props) {
  const [, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [user, setUser] = useState('')

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const user = await Auth.currentSession()
      setUser(user.idToken.payload.given_name)
      userHasAuthenticated(true)
    } catch (e) {
      if (e !== 'No current user') {
        //
      }
    }

    setIsAuthenticating(false)
  }

  async function handleLogout(history) {
    await Auth.signOut()
    userHasAuthenticated(false)
  }

  return (
    <Router handleLogout={handleLogout}>
      <Routes
        appProps={{ isAuthenticated, userHasAuthenticated, user, setUser }}
        handleLogout={handleLogout}
      />
    </Router>
  )
}

export default Main
