import React from 'react'
import { Switch } from 'react-router-dom'

import DashboardLayout from '../layouts/Dashboard'
import AuthenticatedRoute from '../components/AuthenticatedRoute'
import UnAuthenticatedRoute from '../components/UnAuthenticatedRoute'
import NewClient from '../pages/components/NewClient'
import SignIn from '../pages/auth/SignIn'
import Rates from '../pages/components/Rates'
import Returns from '../pages/components/Returns'
import PreviousReturns from '../pages/components/PreviousReturns'
import Clients from '../pages/components/Clients'
import ClientAudit from '../pages/components/ClientAudit'
import Leads from '../pages/components/Leads'
import Plants from '../pages/components/Plants'
import Banks from '../pages/components/Banks'
import Default from '../pages/dashboards/Default'
import SiteData from '../pages/components/SiteData'
import AdminUsers from '../pages/components/AdminUsers'
import ContractPdfGenerate from '../components/ContractPdfGenerate/index'

const Routes = ({ appProps, handleLogout }) => (
  <Switch>
    <UnAuthenticatedRoute
      path="/login"
      exact
      component={SignIn}
      appProps={appProps}
    />
    <UnAuthenticatedRoute
      path="/pdf"
      exact
      component={ContractPdfGenerate}
      appProps={appProps}
    />
    <DashboardLayout handleLogout={handleLogout}>
      <AuthenticatedRoute
        path="/"
        exact
        component={Default}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/banks"
        exact
        component={Banks}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients/new"
        exact
        component={NewClient}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clients"
        exact
        component={Clients}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/client-audit"
        exact
        component={ClientAudit}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/rates"
        exact
        component={Rates}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/returns"
        exact
        component={Returns}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/returns/completed"
        exact
        component={PreviousReturns}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/leads"
        exact
        component={Leads}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/plants"
        exact
        component={Plants}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/site-data"
        exact
        component={SiteData}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/users"
        exact
        component={AdminUsers}
        appProps={appProps}
      />
    </DashboardLayout>
  </Switch>
)

export default Routes
