import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useFormFields } from '../../libs/hooksLib'
import styled from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from '@material-ui/core/styles/withStyles'

import {
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Divider as MuiDivider,
  Grid
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import Alert from '../../components/Alert'
import Indicator from '../../components/Indicator'

const Button = styled(MuiButton)(spacing)

const Divider = styled(MuiDivider)(spacing)

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(30),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(8)}px ${theme.spacing(10)}px ${theme.spacing(
      10
    )}px`,
    position: 'relative'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
  // background: {
  //     backgroundImage: `url('static/img/unsplash/solar-panels.png')`
  // }
})

function SignIn(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [, setUser] = useState({})
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [errorRes, setErrorRes] = useState({
    isError: false,
    message: ''
  })

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0
  }

  async function updateUserPassword() {
    // const myInit = {
    //   headers: {
    //     Authorization: `Bearer ${(await Auth.currentSession())
    //       .getIdToken()
    //       .getJwtToken()}`
    //   }
    // }
    // return await API.patch('users', `/users/update`, {
    //   body: {
    //     email: fields.email,
    //     password: fields.newPassword,
    //     clientId: '1p3gvp35400mijurv6pgbeoskh',
    //     session: user.user.Session,
    //     name: 'Anthony'
    //   }
    // })
  }

  async function updatePassword(event) {
    event.preventDefault()
    try {
      updateUserPassword()
      props.userHasAuthenticated(true)
    } catch (error) {}
  }

  async function handleSubmit(event) {
    setErrorRes({
      isError: false,
      message: ''
    })
    event.preventDefault()
    setIsLoading(true)
    try {
      const cognitoUser = await Auth.signIn(fields.email, fields.password)
      props.setUser(cognitoUser.attributes.given_name)
      setUser(user => ({
        ...user,
        user: cognitoUser
      }))

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired(!newPasswordRequired)
      } else {
        props.userHasAuthenticated(true)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setErrorRes({
        isError: true,
        message: e.message
      })
    }
  }

  const { classes } = props
  return !newPasswordRequired ? (
    <>
      <Grid container justifyContent="center" direction="column">
        <Grid item xs={12} align="center">
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              {errorRes.isError ? <Alert message={errorRes.message} /> : null}
              {isLoading ? <Indicator /> : null}
              <img
                alt="Cloud solar Logo"
                src="/static/img/logos/test.png"
                align="center"
              />
              <hr />
              <form onSubmit={handleSubmit}>
                <Divider
                  className={classes.submit}
                  variant="fullWidth"
                  light={false}
                />
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  type="email"
                  value={fields.email}
                  onChange={handleFieldChange}
                >
                  <InputLabel htmlFor="email">User Name</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    disabled={isLoading}
                  />
                </FormControl>
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={isLoading}
                  />
                </FormControl>
                <Button
                  type="submit"
                  disabled={!validateForm() || isLoading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  mb={2}
                  className={classes.submit}
                >
                  Sign in
                </Button>
              </form>
            </Paper>
          </main>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Grid container spacing={3} justifyContent="center" direction="column">
        <Grid item xs={12} align="center">
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              {errorRes.isError ? <Alert message={errorRes.message} /> : null}
              {isLoading ? <Indicator /> : null}
              <img
                alt="Cloud solar Logo"
                src="/static/img/logos/test.png"
                align="center"
              />
              <hr />
              <form onSubmit={updatePassword}>
                <Divider
                  className={classes.submit}
                  variant="fullWidth"
                  light={false}
                />
                <Typography variant="h5" gutterBottom>
                  Change Your Password
                </Typography>
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                >
                  <InputLabel htmlFor="email">Current Password</InputLabel>
                  <Input
                    id="currentPassword"
                    name="currentPassword"
                    autoFocus
                    disabled={isLoading}
                  />
                </FormControl>
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  type="newPassword"
                  value={fields.newPassword}
                  onChange={handleFieldChange}
                >
                  <InputLabel htmlFor="password">New Password</InputLabel>
                  <Input
                    name="newPassword"
                    type="newPassword"
                    id="newPassword"
                    disabled={isLoading}
                    // autoComplete="current-password"
                  />
                </FormControl>
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  type="confirmNewPassword"
                  value={fields.confirmNewPassword}
                  onChange={handleFieldChange}
                >
                  <InputLabel htmlFor="password">
                    Confirm New Password
                  </InputLabel>
                  <Input
                    name="confirmNewPassword"
                    type="confirmNewPassword"
                    id="confirmNewPassword"
                    disabled={isLoading}
                    // autoComplete="current-password"
                  />
                </FormControl>
                <Button
                  type="submit"
                  disabled={isLoading}
                  // disabled={!validateForm()}
                  fullWidth
                  variant="contained"
                  color="primary"
                  mb={2}
                  className={classes.submit}
                >
                  Change My Password
                </Button>
              </form>
            </Paper>
          </main>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(SignIn)
