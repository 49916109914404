import React from 'react'
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer'
import font from './fonts/Roboto-Regular.ttf'
import boldFont from './fonts/Roboto-Bold.ttf'
import italicFont from './fonts/Roboto-BoldItalic.ttf'
import { format } from 'date-fns'

// Font.register({ family: 'Roboto', src: font })
// Font.register({ family: 'Roboto-Bold', src: boldFont })
// Font.register({ family: 'Roboto-BoldItalic', src: italicFont })

// Create styles
const styles = StyleSheet.create({
  section: {
    fontSize: 12,
    // fontFamily: 'Roboto',
    margin: 14
  },
  heading: {
    fontSize: 16,
    // fontFamily: 'Roboto-Bold',
    fontWeight: 900,
    margin: 14
  },
  subHeading: {
    fontSize: 14,
    // fontFamily: 'Roboto-Bold',
    fontWeight: 700,
    margin: 14
  }
})

const MyDocument = props => (
  <Document>
    <Page size="A4" style={{ margin: 'auto', width: 50, padding: 20 }}>
      <View
        style={[
          styles.section,
          {
            color: '#1957a6',
            textAlign: 'center',
            fontSize: 24
          }
        ]}
      >
        <Text>Digital Solar Agreement (DSA)</Text>
      </View>
      <View
        style={{
          // fontFamily: 'Roboto-BoldItalic',
          fontSize: 8,
          textAlign: 'center',
          color: 'red'
        }}
      >
        <Text>
          {`Contract ID: ${props.contract.contractId}, digitally signed by ${
            props.contract.name
          } on 
          ${format(
            new Date(props.contract.createdAt),
            'PPP'
          )} from ip address ${props.contract.ipAddress}`}
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          Welcome to our www.cloud.solar website (the “Site”). If you elect to
          purchase a Digital Solar Panel (defined below) on this site, you are
          agreeing to be bound by the terms of our Terms of Use, Privacy Policy,
          Pre-Authorized Debit and also the terms of our Digital Solar Purchase
          and Service Agreement as outlined below.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          The Site is an internet Site which provides a market to facilitate the
          purchase of Digital Solar Panel(s) pursuant to the terms of a Digital
          Solar Purchase and Service Agreement where a Client acquires a
          specified number of Digital Solar Panel(s) and authorises CloudSolar
          Inc. as its sole and exclusive agent to make the Client’s Digital
          Solar Panel(s) available to generate electricity. Electricity
          generated from the Digital Solar Panel(s) will then be pooled with the
          electricity produced from all other participating Digital Solar
          Panels. The electricity generated from all the pooled Digital Solar
          Panels will be sold to The Barbados Light & Power Company Limited or
          its successors, assigns replacement or substitute to generate revenue
          for all Clients. This Agreement governs the relationship between
          CloudSolar Inc. and you/Client.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          This Digital Solar Purchase and Service Agreement forms the terms and
          conditions (the “Terms and Conditions”) applicable to the sale of the
          Digital Solar Panel(s) and the use of the Site owned and operated by
          CloudSolar Inc.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          We recommend that interested parties carefully review the helpful
          information, How It Works and FAQs, Terms of Use, Privacy Policy,
          Pre-Authorized Debit, before participating on the Site.
        </Text>
      </View>
      <View style={[styles.section, { fontWeight: 700 }]}>
        <Text>
          THESE TERMS AND CONDITIONS APPLY TO ANY CONTRACT BETWEEN US FOR THE
          SUPPLY OF THE DIGITAL SOLAR PANELS AND THE SERVICES IN CONNECTION
          THEREWITH ON THIS WEBSITE. TO PROTECT YOURSELF AND YOUR OWN INTERESTS
          PLEASE READ THE TERMS AND CONDITIONS OF THIS DIGITAL SOLAR PURCHASE
          AND SERVICE AGREEMENT CAREFULLY BEFORE ACCEPTING THEM. WE WANT YOU TO
          UNDERSTAND EXACTLY WHAT WE ARE BOTH AGREEING TO UNDER THE DIGITAL
          SOLAR PURCHASE AND SERVICE AGREEMENT. IF YOU ARE UNCERTAIN AS TO YOUR
          RIGHTS UNDER THEM OR YOU WANT ANY EXPLANATION ABOUT THEM PLEASE EMAIL
          INFO@CLOUD.SOLAR OR CALL THE NUMBER ON OUR “CONTACT US” PAGE. IF YOU
          WISH TO PROCEED ON THE BASIS THAT THESE TERMS AND CONDITIONS APPLY TO
          ANY ORDER YOU PLACE ON THIS OR ANY SUBSEQUENT OCCASION ON WHICH YOU
          ACCESS OUR WEBSITE AND USE THE PASSWORD YOU WILL SELECT AS PART OF
          YOUR REGISTRATION PROCESS, PLEASE CLICK “ACCEPT” AT THE END OF THE
          TERMS AND CONDITIONS APPEARING BELOW. IF NOT, PLEASE CLICK “DECLINE”
          TO END THIS SESSION. IF YOU WISH TO RETAIN A HARD COPY OF THESE TERMS
          AND CONDITIONS OR SAVE THEM TO YOUR COMPUTER PLEASE CLICK ON THE
          “PRINT” BUTTON.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>BARBADOS</Text>
      </View>
      <View style={[styles.section, { paddingBottom: 0 }]}>
        <Text>
          {`The terms and conditions outlined in this DIGITAL SOLAR PURCHASE AND SERVICE AGREEMENT (hereinafter called the “Agreement”) are made as of the ${format(
            new Date(props.contract.createdAt),
            'do'
          )} day of ${format(
            new Date(props.contract.createdAt),
            'MMMM'
          )}, ${format(new Date(props.contract.createdAt), 'y')}`}
        </Text>
      </View>
      <View style={styles.section}>
        <Text>BETWEEN:</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '20px' }]}>
        <Text>
          1. CLOUDSOLAR INC., a company incorporated and registered under the
          Companies Act, Cap. 308 of the laws of Barbados as Company No. 44356
          and whose registered office is at Highway 7, Maxwell, Christ Church in
          this Island (the“Provider”); and
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '20px' }]}>
        <Text>
          2. The Party described as the holder of the account pursuant to this
          Agreement as provided in the Registration Form and appearing on the
          checkout page on the Site (the “Client”).
        </Text>
      </View>
      <View style={styles.section}>
        <Text>WHEREAS:</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '20px' }]}>
        <Text>
          1. The Provider carries on the business of installing, maintaining and
          operating renewable energy systems and digitally sells solar panels to
          the public (the “Services” which is more particularly described
          below);
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '20px' }]}>
        <Text>2. The Client wishes to receive the Services;</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '20px' }]}>
        <Text>
          3. The Provider is willing to provide the Services and the Client is
          willing to engage the Provider to provide the Services all in
          accordance with the provisions of this Agreement;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          THEREFORE in consideration of the mutual promises detailed in this
          Agreement and for other good and valuable consideration, the
          sufficiency of which is acknowledged, the Provider and the Client
          hereby agree to the following terms and conditions of this Agreement.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>1. DEFINTIONS AND INTERPRETATION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.1. “the BLPC” means the Barbados Light and Power Limited, a company
          incorporated under the Companies Act, Cap. 308 of the laws of
          Barbados.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.2. “Business Day” means any day (other than a Saturday or Sunday or
          public/bank holiday) on which commercial banks in Bridgetown, Barbados
          are open for business.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.3. “Capital Depreciated Value” means the value of the Digital Solar
          Panels at the time of resale which is calculated at the depreciation
          rate at 4% per year over the 25 year life of the Digital Solar
          Panel(s).
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.4. “Commencement Date” means the date of this Agreement first
          mentioned above.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.5. “Digital Solar Panels”“Digital Solar Panels” means total number
          of photo-voltaic solar panels purchased by the Client from the
          Provider being a minimum number of 1 and a maximum number of 20
          panels.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.6. “Feed-In Tariff” means the applicable feed-in tariff for
          renewable energy technologies charged by The Barbados Light and Power
          Company Limited, as approved and amended by the Fair Trading
          Commission or any other relevant regulator from time to time.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.7. “Licensed Partner” means the owner of a roof or land which houses
          the Pooled Solar Network pursuant to the terms of a licence/lease
          agreement between the Licensed Partner and the Provider.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.8. “Lock Out Period” means the period of one year immediately from
          the Commencement Date.
        </Text>
      </View>
      <View style={[styles.section, { marginTop: 20 }]}>
        <Text>
          1.9. “Party” shall mean the Provider or the Client and “Parties” shall
          mean the Provider and the Client jointly. Either Party or the Parties
          include its or their respective successors-in-title and permitted
          assignees;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.10. “Pooled Solar Network” means the total number of Digital Solar
          Panels participating in the Pooled Solar Network including solar
          generating assets whose combined energy production will be pooled with
          the electricity produced from all Digital Solar Panels and sold to
          generate revenue for all the Providers’ clients and will also be used
          to determine the proportionate Solar Credit payable to the Client;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.11. “Production Meter” means the meter which will record the energy
          generated by the Digital Solar Panels and sold directly to BLPC. The
          readings on the Production Meter showing energy generated by the
          Digital Solar Panels will be used to determine the Solar Credit and
          the Renewable Energy Credits (“REC”) generated by the Digital Solar
          Panels.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.12. “Registration Form” means the online application form appearing
          on the Site to be completed by a proposed Client in respect of their
          purcahse of the Digital Solar Panel(s).
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.13. “Production Month” means the full calendar month for which
          energy is produced by the Digital Solar Panels and delivered to the
          Provider at the Production Meter. The energy generated during the
          Production Month will be credited to the Client’s next monthly Solar
          Account.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.14. “Service(s)” means installation, repair and management services
          undertaken by the Provider in respect of the Pooled Solar Network and
          more fully described in Schedule 1 of this Agreement;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.15. “Solar Credit” means the energy credit, in the form of a
          kilowatt hour (kWh) credit, from the Provider to the Client’s account
          for the energy generated by the Digital Solar Panels and transmitted
          to the Pooled Solar Network. A kWh credit converts to a dollar value
          based on the applicable Feed-In-Tariff rate;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.16. “Solar Credit Deductible” means the internal maintenance fee
          chargeable by and payable to the Provider in accordance with Clause
          4.5 of this Agreement;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.17. “Solar Renewable Energy Credits” or “RECs” or “S-RECs” mean the
          renewable energy attributes associated with the Pooled Solar Network.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.18. “Sub-Contractor” means an eligible third party that enters into
          a sub-contract with the Provider in respect of any part of the
          Services, not being a majority of the Services.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.19. “Term” refers to the period from the Commencement Date for a
          period of 25 years;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.20. “Termination Date” means the date this Agreement is terminated
          under Clause 13 of this Agreement;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.21. References to numbered clauses and schedules are references to
          the relevant Clauses and Schedules in this Agreement;
        </Text>
      </View>
      <View style={[styles.section, { marginTop: 10 }]}>
        <Text>
          1.22. Headings to numbered clauses are for convenience only and will
          not affect the interpretation of this Agreement;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>1.23. Words importing any gender include every gender;</Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.24. Words importing the singular number include the plural number
          and vice versa;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.25. Words importing persons include firms, companies and
          corporations and vice versa;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.26. Any obligation on any Party not to do or omit to do anything is
          to include an obligation not to allow that thing to be done or omitted
          to be done;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.27. Any reference to an enactment includes reference to that
          enactment as amended or replaced from time to time and to any
          subordinate legislation made under that enactment;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          1.28. Where the word ‘including’ is used in this Agreement, it shall
          be understood as meaning ‘including without limitation.’
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>2. TERM</Text>
      </View>
      <View style={styles.section}>
        <Text>
          2.1. The Client shall engage the Provider to perform the Services for
          the Term with effect from the Commencement Date, (subject to earlier
          termination pursuant to Clause 13) and shall terminate automatically
          without notice on expiry of the Term.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>3. AGREEMENT TO PURCHASE</Text>
      </View>
      <View style={styles.section}>
        <Text>
          3.1. Pursuant to this Agreement, the Client agrees to purchase the
          number of Digital Solar Panels identified on the check-out page of the
          Site for the Purchase Price outlined therein.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          3.2. The Client agrees that purchase and sale of the Digital Solar
          Panels are conducted and concluded in Barbados.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          3.3. The Client may purchase additional panels up to a maximum of
          twenty (20) Digital Solar Panels (inclusive of Digital Solar Panels
          already purchased) as available for purchase and offered by the
          Provider from time to time.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          3.4. The Client hereby appoints and the Provider agrees to act, as the
          Client’s sole and exclusive lawful agent in connection with the
          activities relating to the generation of electricity of the Digital
          Solar Panels owned by the Client.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          3.5. The Client hereby makes its Digital Solar Panels available for
          use exclusively by the Provider for the purposes of the Pooled Solar
          Network and authorises the Provider, the right and absolute authority
          in its sole direction to:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (i) install or move the Digital Solar Panels located on the property
          of a Licensed Partner;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (ii) maintain and effect repairs, as necessary, to the Digital Solar
          Panels;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iii) pool the output of energy generated from the Digital Solar
          Panels with all other digital solar panels in the Pooled Solar
          Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iv) negotiate, agree, and execute any agreements with respect to the
          licensing, insurance required in respect of activities involving the
          Digital Solar Panels, the sale of the energy derived from the Digital
          Solar Panels, including collecting any reimbursements of Solar Credits
          due to the Client from a Licenced Partner and applying the Solar
          Credits to the Client’s solar account for the benefit of the Pooled
          Solar Network, and any other matters relating thereto;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (v) undertake ongoing maintenance and repairing works in respect of
          the Digital Solar Panels, whether directly or through experienced
          Sub-Contractors, in accordance with the terms of this Agreement;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vi) sell all energy generated from all pooled Digital Solar Panels,
          including the Digital Solar Panels the subject of this Agreement, to
          the BLPC at the applicable Feed In Tariff rate indicated by BLPC,
          and/or under any applicable law, from time to time; and
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vii) to do all such things as necessary from time to time as required
          for the sale of the energy generated from the Pooled Solar Network,
          including the output generated by the Digital Solar Panels.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>4. SUBSCRIPTION AND CALCULATION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          4.1. At the end of each Production Month, the Provider undertakes to:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (i) aggregate all the energy generated by the Pool Solar Network which
          will be measured by a Production Meter in kilowatt hours (kWh) for
          that Production Month. This total energy generated will then be
          divided by the total available digital solar panels in the Pool Solar
          Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (ii) determine the total energy generated by the total number of
          Digital Solar Panels owned by the Client, net of adjustments made for
          the Solar Credit Deductible payable in section 4.5 below (the “Total
          Net Energy”);
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iii) assess the total Solar Credits payable to the Client which is
          calculated based on the energy generated by the Digital Solar Panels
          for each Production Month;
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          4.2. The total Solar Credits payable will be determined by multiplying
          by the Total Net Energy produced by the Client’s Digital Solar Panels
          by the applicable Feed-In Tariff.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          4.3. The Solar Credit generated in respect of each Production Month
          will be applied to the Client’s solar account within twenty-one (21)
          business days of the end of each Production Month.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          4.4. Within twenty-one (21) days of the Production Month, the Provider
          will undertake an audit of its accounting systems with respect to the
          calculation of the Solar Credits and reserves the right to adjust the
          dispersed credits if errors or audit errors are revealed.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          4.5. The Solar Credit calculation will include an adjustment for the
          Solar Credit Deductible, being a deduction made from energy generated
          from the Digital Solar Panels payable to the Provider. The Solar
          Credit Deductible will be deducted based on the Client’s proportionate
          interest in the pooled revenue for each Production Month. The Solar
          Credit Deductible adjustment for the Pooled Solar Network is 20% per
          kWh.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>5. BILLING AND PAYMENT</Text>
      </View>
      <View style={styles.section}>
        <Text>
          5.1. Subject to the completion of the auditing process in section 4.5
          above, the Provider will, on receipt of the funds generated from the
          sale of the energy the BPLC, deposit the funds in the Client’s bank
          account in accordance with section 5.2 of this Agreement.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          5.2. All payments due to the Client in accordance with this Agreement
          shall be made by way of electronic funds transfer to the appropriate
          bank account specified on the customer details page located on the
          check-out page of the Site.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>6. SOLAR RENEWABLE ENERGY CREDITS</Text>
      </View>
      <View style={styles.section}>
        <Text>
          6.1. The Client acknowledges and agrees that any environmental
          attributes, including RECs, associated with the Pooled Solar Network
          are transferred to the Provider through the Solar Credit.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          6.2. The Client acknowledges that the Solar Credit includes an
          adjustment for the Solar Credit Deductible of the SRECs produced by
          the Digital Solar Panels that are used by the Provider for operation
          of the Pooled Solar Network.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>7. SOLAR ASSET MANAGEMENT</Text>
      </View>
      <View style={styles.section}>
        <Text>
          7.1. The Client acknowledges and agrees that the Provider and/or
          parties responsible for operations and maintenance of the Pooled Solar
          Network assets may, at its sole discretion, repair or replace any
          equipment in the Pooled Solar Network including the Digital Solar
          Panels as necessary without notice to the Client of such change(s).
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          7.2. In the event of equipment damage resulting in the failure to
          produce energy, no Solar Credits will be made to the Client for that
          portion of the energy output. The Provider is held harmless for lost
          energy production due to operations and maintenance schedules,
          failures, or repairs.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          7.3. Save for the Digital Solar Panels, the Client does not have any
          claim for ownership of any of the solar energy assets or resources in
          the Pooled Solar Network.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          7.4. The Client does not assume any cost, responsibility or liability
          for the ongoing operations and maintenance of the solar energy
          systems.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>8. INSURANCE</Text>
      </View>
      <View style={styles.section}>
        <Text>
          8.1. Throughout the Term, the Provider as the lawful agent of the
          Client shall obtain, maintain and pay for one or more insurance
          policies with a reputable insurance company to cover the Digital Solar
          Panels.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          8.2. The Provider shall separately obtain and maintain and keep in
          effect insurance coverage in respect of any liabilities that may arise
          from the Provider and its employees and agents performing the
          Services.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          8.3. At the request of the Client, the Provider shall supply to the
          Client a copy of the insurance policies or certificates of insurance
          which remain in force and which contain reasonable detail of the
          required coverage referred to in section 8.1 of this Agreement.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>9. REPRESENTATIONS AND WARRANTIES OF THE PROVIDER</Text>
      </View>
      <View style={styles.section}>
        <Text>9.1. The Provider represents, warrants and undertakes that:</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          9.1.1. it shall carry out the Services with reasonable care, due skill
          and diligence and timely manner in accordance with current
          professional industry standards and best practices within the industry
          of the Provider;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          9.1.2. the Provider’s employees and agents will have the necessary
          skills, professional qualifications and experience to perform the
          Services;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          9.1.3. it is duly incorporated and validly existing under the laws of
          Barbados and has full capacity and authority to enter into this
          Agreement and to exercise its rights and perform its obligations under
          this Agreement;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          9.1.4. the licence agreement(s) between the Provider and Licensed
          Provider(s) is/are in full force and effect and has/have not been
          revoked and that the Provider is authorised thereunder to affix the
          Digital Solar Panels on the roofs of the Licensed Service Provider(s);
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          9.2. The Provider acknowledges that the Client will be relying on the
          Provider’s skills, expertise and experience concerning the provision
          of the Services.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>10. REPRESENTATIONS AND WARRANTIES OF THE CLIENT</Text>
      </View>
      <View style={styles.section}>
        <Text>10.1. The Client represents, warrants and undertakes that:</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          10.1.1. if a corporation, it is duly incorporated validly existing and
          in good standing under the laws of Barbados and has full capacity and
          authority to enter into this Agreement and to exercise its rights and
          perform its obligations under this Agreement;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          10.1.2. if an individual, a resident in Barbados and at least 18 years
          old;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          10.1.3. it is the holder of a bank account in Barbados in its name or
          company’s name as the case may be;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          10.1.4. due diligence information on the Client submitted to the
          Provider are true, accurate and complete.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          10.2. The Client authorizes the Provider to conduct make identity and
          money laundering verifications as part of its statutory obligations
          under the laws of Barbados and to forward, disclose or supply any
          information supplied to Provider, without further consent, to any
          authorities including governmental and regulatory authorities or any
          financial institution at any time, as required under applicable law.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>11. CONFIDENTIALITY AND USE OF INFORMATION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          11.1. A Party (the “Receiving Party”) shall keep the confidential
          information of the other Party (the “Supplying Party”) confidential
          and secret, whether disclosed to or received by the Receiving Party.
          The Receiving Party shall only use the confidential information of the
          Supplying Party for performing the Receiving Party’s obligations under
          this Agreement. The Receiving Party shall inform its officers,
          employees and agents of the Receiving Party’s obligations under the
          provision of this Clause, and ensure that its officers, employees and
          agents meet the obligations.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          11.2. The obligations of Clause 11.1 shall not apply to any
          information which:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          11.2.1. was known or in the possession of the Receiving Party before
          it was provided to the Receiving Party by the Supplying Party;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          11.2.2. is, or becomes, publicly available through no fault of the
          Receiving Party;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          11.2.3. is provided to the Receiving Party without restriction of
          disclosure by a third party, who did not breach any confidentiality
          obligations by making such a disclosure;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          11.2.4. was developed by the Receiving Party (or on its behalf) who
          has no direct access to, or use or knowledge of the confidential
          information supplied by the Supplying Party; or
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          11.2.5. is required to be disclosed by order of a court of competent
          jurisdiction.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>12. DATA PROTECTION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          12.1. Subject to applicable law, the Provider agrees to only use any
          personal information provided by the Client for the purposes of
          providing the Services including its anti-money laundering
          obligations, or for informing the Client of the availability of
          similar services, unless the Client otherwise agrees.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>13. SUB-CONTRACTORS</Text>
      </View>
      <View style={styles.section}>
        <Text>
          13.1. The Provider is permitted to use other persons to provide all or
          part of the Services, at the Provider’s sole cost and expense, and
          shall be responsible for the work of a sub-contractor whose work shall
          be undertaken to the same standard as stated in this Agreement.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>14. TERM AND TERMINATION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.1. This Agreement will expire at the end of the Term and on its
          expiry, where the parties agree to a new Agreement, the Provider will
          remove the existing Digital Solar Panels and replace them with new
          Digital Solar Panels.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.2. Where the Client elects not to enter into a new Agreement
          pursuant to section 14.1 above, the Provider will use commercially
          reasonable efforts to obtain the sale of the Digital Solar Panels and
          the proceeds obtained from the sale will be paid to the Client net of
          expenses and costs including the cost of removal.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.3. The Provider may, in its sole discretion, terminate this
          Agreement other than for breach by providing thirty (30) days’ written
          notice to the Client.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.4. In consideration of the sum of BDS$1.00 (receipt and sufficiency
          of which the Client acknowledges) the Provider shall have the option
          to purchase from the Client, any or all of the Digital Solar Panels
          (the “Option”) at the Capital Depreciated Value for the Digital Solar
          Panels at the time of the exercise of the Option in circumstances
          where no Feed-In-Tariff is available or the Feed in Tariff is revised
          in a manner which the Provider determines, in its sole discretion, is
          not commercially viable. The Provider shall exercise the Option by
          giving the Client notice in writing and the purchase shall be
          concluded in the time and pursuant to the terms specified in the said
          notice.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.5. Either party may terminate the Agreement if the other party
          fails to perform any of the terms and conditions on its part to be
          observed and performed and fails to remedy such breach within thirty
          (30) days after receipt of notice of such breach from the
          non-breaching party upon written notice on the breaching party
          declaring that this Agreement is terminated with immediate effect.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          14.6. The Client may elect to terminate this Agreement other than for
          breach, including circumstances where no Feed-In Tariff rate is
          available or the Feed-in Tariff rate is revised, subject to the terms
          below:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          14.6.1. The Client will be subject to payment of a set-up fee
          calculated as 15% of the Purchase Price of the Digital Solar Panels
          where the Client terminates this Agreement during the Lock Out Period.
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          14.6.2. Where the Client elects to terminate this Agreement after the
          expiry of the Lock Out Period, the Client may either:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '60px' }]}>
        <Text>
          14.6.2.1. offer the Digital Solar Panels to the Provider for purchase
          at the Capital Depreciated Value of the Digital Solar Panels on
          termination;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '60px' }]}>
        <Text>
          14.6.2.2. direct the Provider as its lawful agent to engage in the
          marketing and sale of the Digital Solar Panels at the fair market
          value, subject to a commission of 10% for the sale price; or
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '60px' }]}>
        <Text>
          14.6.2.3. on payment of a removal fee of 10% of the value of the
          Digital Solar Panels on the date of purchase, direct the Provider to
          remove the Panels from the Pooled Solar Network.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>15. NOTICES</Text>
      </View>
      <View style={styles.section}>
        <Text>
          15.1. All notices under this Agreement shall be in writing and must be
          in English.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          15.2. Any notice to be given under this Agreement shall be sent by
          ordinary mail, or by facsimile or e-mail, to:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.2.1. In the case of the Client, to the address outlined in the
          Registration Form.
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.2.2. In the case of the Provider, to the address outlined on the
          "Contact Us" page on the site.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>15.3. Notices shall be deemed to have been duly given:</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.3.1. when delivered, if delivered by courier or other messenger
          during normal business hours of the recipient; or
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.3.2. on the next Business Day after transmission, if transmitted by
          fax or e-mail; or
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.3.3. three (3) Business Days after the date of posting, in the case
          of ordinary mail; or
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          15.3.4. seven (7) Business Days after the date of posting, if sent by
          airmail; in each case addressed to the addressees noted above or such
          other persons as that Party may from time to time notify the other
          Party in accordance with this Clause.
        </Text>
      </View>
      <View style={styles.section}>
        <Text>
          15.4. In proving the giving of a notice it shall be sufficient to
          prove that the notice was left or that the envelope containing the
          notice was properly addressed and posted, or that the applicable means
          of telecommunication was addressed and despatched and despatch of the
          transmission was confirmed and/or acknowledged as the case may be.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>16. GENERAL TERMS</Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.1 Force majeure</Text>
      </View>
      <View style={styles.section}>
        <Text>
          The Provider shall not be liable or be deemed to be in breach of this
          Agreement for any delays or failures in performance of this Agreement
          which result from circumstances beyond the reasonable control, if the
          delay or failure results from any of the following:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>(i) Acts of God;</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (ii) outbreak of hostilities, riots, civil disturbance, acts of
          terrorism;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iii) the act of any government or authority (including refusal or
          revocation of any licence or consent);
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>(iv) fire, explosion, flood, persistent bad weather;</Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (v) power failure (including the failure of the equipment and grid
          operated by the Barbados Light & Power Limited) failure or breakdown
          of plant, machinery or equipment in respect of the Pooled Solar
          Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vi) bankruptcy of the Barbados Light & Power Limited or any purchaser
          of the energy generated from the Pool Solar Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vii) theft, malicious damage, strike, lock-out or industrial action
          of any kind;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (viii) pandemic, epidemic, or other declared public health emergency
          declared in Barbados; and
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (ix)any cause or circumstance whatsoever beyond the Provider’s
          reasonable control.
        </Text>
      </View>
      <View style={[styles.section, { marginTop: '20px' }]}>
        <Text>
          If such circumstances continue for a continuous period of more than
          six (6) months, the Parties may terminate this Agreement only by
          written notice to the other Party.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.2 Severability</Text>
      </View>
      <View style={styles.section}>
        <Text>
          If any provision of this Agreement is or becomes prohibited by law or
          is judged by a court to be unlawful, void or unenforceable, the
          provision shall, to the extent required, be severed from this
          Agreement and rendered ineffective as far as possible without
          modifying the remaining provisions of this Agreement, and shall not in
          any way affect any other circumstances of or the validity or
          enforcement of the remainder of this Agreement.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.3 Amendments</Text>
      </View>
      <View style={styles.section}>
        <Text>
          This Agreement may not be released, discharged, supplemented, amended,
          varied or modified in any manner except by an instrument in writing
          signed by a duly authorised officer or representative of each of the
          Parties.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.4 Assignment</Text>
      </View>
      <View style={styles.section}>
        <Text>
          Neither this Agreement, nor any rights or obligations hereunder, may
          be assigned or transferred by the Client without the prior written
          approval of the Provider.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.5 Entire Agreement</Text>
      </View>
      <View style={styles.section}>
        <Text>
          This Agreement, the Schedules thereto, and the How It Works and FAQs,
          Terms of Use, Privacy Policy, and Pre-Authorized Debit contain the
          whole agreement between the Parties in respect of the subject matter
          of this Agreement and supersedes and replaces any prior written oral
          agreements, representations and understanding between them. The
          Parties confirm that they have not entered into this Agreement on the
          basis of any representation that is not expressly incorporated into
          this Agreement.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>16.6 Set Off</Text>
      </View>
      <View style={styles.section}>
        <Text>
          Unless otherwise provided in the Agreement, where either Party has
          incurred any liability to the other Party, whether under this
          Agreement or otherwise, and whether such liability is liquidated or
          unliquidated each Party may set off the amount of such liability
          against any sum that would otherwise be due to the other Party under
          this Agreement.
        </Text>
      </View>
      <View style={styles.subHeading}>
        <Text>17. EFFECTIVE DATE</Text>
      </View>
      <View style={styles.section}>
        <Text>
          17.1 This Agreement becomes effective on the Client's receipt of a
          confirmation email from the Provider notifying the Client of their
          successful registration on the Site and payment for their Digital
          Solar Panels has been completed.
        </Text>
      </View>
      <View style={styles.heading}>
        <Text>18. LAW AND JURISDICTION</Text>
      </View>
      <View style={styles.section}>
        <Text>
          18.1 This Agreement and all matters arising from it shall be governed
          by and construed in accordance with the laws of Barbados and the
          parties shall irrevocably submit to the exclusive jurisdiction of the
          courts of Barbados.
        </Text>
      </View>
      <View
        style={[
          styles.section,
          {
            fontSize: 28,
            textAlign: 'center'
          }
        ]}
      >
        <Text>SCHEDULE 1</Text>
        <Text>Provider’s Services</Text>
      </View>
      <View
        style={[
          styles.section,
          {
            fontSize: 28,
            textAlign: 'center'
          }
        ]}
      >
        {/* <Text>Provider’s Services</Text> */}
      </View>
      <View style={styles.section}>
        <Text>
          The management services offered by the Provider in relation to the
          Digital Solar Panels will include the following:
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (i) identify, negotiate execute a lease/license agreement of property
          on which to install the Digital Solar Panels and arrange for the
          installation of the Digital Solar Panels on the property;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (ii) use all reasonable endeavours to ensure the Pooled Solar Network
          is fully operational and each component is operating at capacity and
          within warranties;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iii) inspect and as necessary, maintain the Digital Solar Panels and
          all software, hardware, plant and equipment used in the Pooled Solar
          Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (iv) if deemed necessary, in the Provider’s sole discretion, obtain
          professional services and advice in connection with the maintenance of
          the Digital Solar Panels and the Pooled Solar Network;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (v) obtain insurance coverage in respect of the Digital Solar Panels;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vi) provide monthly and quarterly updates for the Client in relation
          to the performance results of the Digital Solar Panels and the Pooled
          Solar Network and any other matters which the Provider considers
          material concerning the Pooled Solar Network accessible at
          www.cloud.solar/performance;
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (vii) pool the energy generated from the Digital Solar Panels with
          other digital solar panels in the Pooled Solar Network by other
          Clients and undertake all marketing activities in respect of the sale
          or future sale of the generated energy; and
        </Text>
      </View>
      <View style={[styles.section, { paddingLeft: '40px' }]}>
        <Text>
          (viii) provide any other service or thing which, in the reasonable
          opinion of Provider, is incidental or ancillary to the ongoing
          management of the Digital Solar Panels.
        </Text>
      </View>
    </Page>
  </Document>
)

export default function ContractPdfGenerate(props) {
  return (
    <PDFDownloadLink
      document={<MyDocument contract={props.contract} />}
      fileName={`${props.contract.name}-contract.pdf`}
    >
      Download
    </PDFDownloadLink>
  )
}
