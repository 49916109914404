import React from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import { Grid, Divider as MuiDivider, Typography } from '@material-ui/core'

const Divider = styled(MuiDivider)(spacing)

function SiteData(props) {
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        Site Data
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SiteData
