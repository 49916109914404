import React, { createRef, useRef, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import {
  makeStyles,
  createTheme,
  withStyles,
  ThemeProvider
} from '@material-ui/core/styles'
import config from '../../config'
import MaterialTable from 'material-table'
import numeral from 'numeral'
import MuiAlert from '@material-ui/lab/Alert'
import SaveIcon from '@material-ui/icons/Save'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faPassport,
  faUserCheck,
  faFileContract,
  faPiggyBank
} from '@fortawesome/pro-light-svg-icons'
import { CSVLink } from 'react-csv'
import { addMonths } from 'date-fns'

import {
  Button,
  CardHeader,
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormGroup,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Link,
  Tabs,
  Tab,
  Box,
  Avatar
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { format } from 'date-fns'
import PdfPagination from '../../components/PdfPagination'
import ContractPdfGenerate from '../../components/ContractPdfGenerate'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import WarningIcon from '@material-ui/icons/Warning'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import CheckCircle from '@material-ui/icons/CheckCircle'

import { pdfjs, Document, Page } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 400
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170'
    // padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginBottom: '3vh'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faImage: {
    fontSize: 300,
    color: 'Dodgerblue'
  },
  nextButton: {
    background: '#fbb116',
    border: '0 none',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.175)',
    borderRadius: '5px',
    // color: '#ffffff',
    cursor: 'pointer',
    display: 'inline-block',
    // fontFamily: 'ProximaNovaSemibold',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '50px',
    padding: '0 23px',
    textDecoration: 'none',
    // width: 'auto',
    minWidth: '300px',
    textAlign: 'center',
    marginTop: 20
  }
}))

const ColorButton = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button)

const theme = createTheme({
  palette: {
    primary: green
  }
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Clients(props) {
  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [deleteOpen, setDeleteOpen] = useState(false)
  let tableRef = createRef()
  let idStatusRef = useRef(null)
  let bankName = useRef(null)
  let bankBranch = useRef(null)
  let bankCode = useRef(null)
  let bankAccountName = useRef(null)
  let bankAccountNumber = useRef(null)
  let bankAccountType = useRef(null)
  let residencyStatusRef = useRef(null)
  let bankStatusRef = useRef(null)
  let messageRef = useRef(null)
  let userName = ''
  let name = ''
  let email = ''
  let poolId = config.cognito.CLIENT_USER_POOL_ID

  const [state, setState] = useState({
    clients: [],
    pendingClients: [],
    inActiveClients: [],
    contracts: [],
    rowData: {},
    idStatus: '',
    fields: {
      idStatus: '',
      residencyStatus: '',
      paymentStatus: '',
      activeDate: ''
    }
  })

  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleDeleteConfirm = userName => {
    adminDeleteUser(userName)
    setDeleteOpen(false)
    //TODO delete contracts
  }

  const handleActiveTabChange = (event, value) => {
    setActiveTab(value)
  }

  const signupStatus = steps => {
    const { completed, subStep, step } = steps

    if (completed === 'true') {
      return 'Completed'
    } else if (subStep === '1') {
      return 'Confirm Email'
    } else if (subStep === '2') {
      return 'Address'
    } else if (subStep === '3') {
      return 'Identification'
    } else if (step === '1') {
      return 'Panels'
    } else if (step === '2') {
      return 'Bank'
    } else {
      return 'Review'
    }
  }

  let rows = []
  let pendingColumns = [
    {
      title: 'Name',
      field: 'fullName',
      defaultSort: 'asc'
    },
    {
      title: 'Panels',
      field: 'custom:totalPanels',
      customSort: (a, b) => {
        console.log(a['custom:totalPanels'], b['custom:totalPanels'])
        return (
          parseInt(a['custom:totalPanels'] || 0) -
          parseInt(b['custom:totalPanels'] || 0)
        )
      }
    },
    {
      title: 'kWp',
      field: 'custom:totalKwp'
    },
    {
      title: 'Contracts',
      field: 'custom:totalContracts',
      render: rowData =>
        numeral(rowData['custom:totalContracts']).format('0,0'),
      customSort: (a, b) => {
        return (
          parseInt(a['custom:totalContracts'] || 0) -
          parseInt(b['custom:totalContracts'] || 0)
        )
      }
    },
    {
      title: 'Status',
      render: rowData =>
        signupStatus({
          completed: rowData['custom:signupCompleted'],
          subStep: rowData['custom:activeSubStep'],
          step: rowData['custom:activeStep']
        })
    }
  ]
  let columns = [
    {
      title: 'Name',
      field: 'fullName',
      defaultSort: 'asc'
    },
    {
      title: 'Panels',
      field: 'custom:totalPanels',
      customSort: (a, b) => {
        console.log(a['custom:totalPanels'], b['custom:totalPanels'])
        return (
          parseInt(a['custom:totalPanels'] || 0) -
          parseInt(b['custom:totalPanels'] || 0)
        )
      }
    },
    {
      title: 'kWp',
      field: 'custom:totalKwp'
    },
    {
      title: 'Contracts',
      field: 'custom:totalContracts',
      render: rowData =>
        numeral(rowData['custom:totalContracts']).format('0,0'),
      customSort: (a, b) => {
        return (
          parseInt(a['custom:totalContracts'] || 0) -
          parseInt(b['custom:totalContracts'] || 0)
        )
      }
    },
    {
      title: 'Credits',
      field: 'custom:creditsTotal'
    },
    {
      title: 'Verified',
      render: rowData =>
        rowData['custom:idVerified'] === 'idVerified' &&
        rowData['custom:residencyVerified'] === 'residencyVerified'
          ? 'Yes'
          : 'No'
    },
    {
      title: 'Active',
      render: rowData =>
        rowData['custom:isActive'] ? (
          'Active'
        ) : (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              startIcon={<CheckCircle />}
              disabled={rowData.active}
              color="primary"
              size="small"
              onClick={() => activateUser(rowData['sub'])}
            >
              Activate
            </ColorButton>
          </ThemeProvider>
        )
    }
    // {
    //   title: 'Delete',
    //   field: 'name',
    //   render: rowData =>
    //     <DeleteDialog userName={rowData['sub']} signupCompleted={rowData['custom:signupCompleted'] === 'true'} />
    // },
  ]

  function DeleteDialog(props) {
    return (
      <>
        <Button
          disabled={props.signupCompleted}
          style={{ fontWeight: 700, color: red[600] }}
          onClick={() => handleDeleteUser()}
        >
          Delete User
        </Button>
        <Dialog
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: 10
            }}
          >
            <WarningIcon
              aria-owns={deleteOpen ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              style={{ color: red[500], marginLeft: 30 }}
              fontSize="large"
            />
            {/* </WarningIcon> */}
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete this user?
            </DialogTitle>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting this user will also delete any inactive contracts
              associated with this user.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} style={{ color: green[600] }}>
              No, cancel delete
            </Button>
            <Button
              onClick={() => handleDeleteConfirm(props.userName)}
              style={{ color: red[600] }}
              autoFocus
            >
              Yes, delete the user
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const getContractsForUser = async userId => {
    return await API.get('contracts', `/contracts/admin/${userId}`)
  }

  const activateContractAddTransaction = async data => {
    const { rowData, parentRowData } = data
    const contract = {
      awsUserId: rowData.userId,
      amount: parseFloat(rowData.amount),
      contractType: rowData.contractType,
      term: rowData.term,
      fitRate: parseFloat(rowData.fitRate),
      pricePerKw: parseInt(rowData.pricePerKw),
      panels: parseInt(rowData.panels),
      active: true,
      activeDate: new Date().toISOString(),
      endDate: addMonths(new Date(), 300).toISOString()
    }

    const contractId = rowData.contractId
    await API.put('contracts', `/contracts/adminUpdate/${contractId}`, {
      body: contract
    })
    let emailData = {}
    emailData.email = parentRowData.email
    emailData.name = parentRowData.fullName
    emailData.type = 'contractActivated'
    await sendEmail(emailData)
    getUsers()
    setOpen(true)
    // await API.put('returns', `/contracts/adminUpdate/${contractId}`, {
    //   body: contract
    // })
    //TODO Update email copy. Return from api to update state. SnackBar.
    //Create transaction entry on return table
  }

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const active = []
      const pending = []
      const inActive = []
      const rawUsers = await API.get('users', `/users/${poolId}`)
      rawUsers.forEach((u, idx) => {
        if (
          u['custom:signupCompleted'] === 'true' &&
          u['custom:isActive'] !== 'true'
        ) {
          inActive.push(u)
        }
        if (u['custom:isActive'] === 'true') {
          active.push(u)
        }
        if (u['custom:signupCompleted'] === 'false') {
          pending.push(u)
        }
      })
      setState(() => ({
        clients: active,
        inActiveClients: inActive,
        pendingClients: pending,
        rows
      }))
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function sendEmail(data) {
    return await API.post('email', '/email', {
      body: data
    })
  }

  async function handleUserUpdate(e, userName) {
    e.preventDefault()
    const userAttributes = [
      {
        Name: 'custom:bankName',
        Value: bankName.current.value.toString()
      },
      {
        Name: 'custom:bankBranch',
        Value: bankBranch.current.value.toString()
      },
      {
        Name: 'custom:bankCode',
        Value: bankCode.current.value.toString()
      },
      {
        Name: 'custom:bankAccountNumber',
        Value: bankAccountNumber.current.value.toString()
      },
      {
        Name: 'custom:bankAccountType',
        Value: bankAccountType.current.value.toString()
      }
    ]
    //To do add default message if account active and active date is today
    const data = {
      UserAttributes: userAttributes,
      UserPoolId: config.cognito.CLIENT_USER_POOL_ID,
      Username: userName
    }
    try {
      await API.patch('users', `/users/adminUpdate`, {
        body: data
      })
      getUsers()
      setOpen(true)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleSubmit(e, userName, name, email) {
    e.preventDefault()
    const bAccountMessage = messageRef.current.value.toString() !== ''
    const userAttributes = [
      {
        Name: 'custom:idVerified',
        Value: idStatusRef.current.value.toString()
      },
      {
        Name: 'custom:residencyVerified',
        Value: residencyStatusRef.current.value.toString()
      },
      {
        Name: 'custom:bankAccountVerified',
        Value: bankStatusRef.current.value.toString()
      },
      {
        Name: 'custom:accountMessage',
        Value: messageRef.current.value.toString()
      }
    ]
    //To do add default message if account active and active date is today
    const data = {
      UserAttributes: userAttributes,
      UserPoolId: config.cognito.CLIENT_USER_POOL_ID,
      Username: userName
    }
    try {
      await API.patch('users', `/users/adminUpdate`, {
        body: data
      })
      if (bAccountMessage) {
        let data = {}
        if (bAccountMessage) {
          data.email = email
          data.name = name.substr(0, name.indexOf(' '))
          data.message = messageRef.current.value.toString()
          data.type = 'accountMessage'
        }
        await sendEmail(data)
      }
      getUsers()
      setOpen(true)
    } catch (e) {
      console.log(e)
    }
  }

  async function activateUser(userName) {
    const data = {
      UserAttributes: [
        {
          Name: 'custom:isActive',
          Value: 'true'
        }
      ],
      UserPoolId: config.cognito.CLIENT_USER_POOL_ID,
      Username: userName
    }
    try {
      await API.patch('users', `/users/adminUpdate`, {
        body: data
      })
      getUsers()
      setOpen(true)
    } catch (e) {
      console.log(e)
    }
  }

  function handleDeleteUser() {
    setDeleteOpen(true)
  }

  async function adminDeleteUser(userName) {
    const data = {
      UserPoolId: config.cognito.CLIENT_USER_POOL_ID,
      Username: userName
    }
    try {
      await API.patch('users', `/users/adminDeleteUser`, {
        body: data
      })
      getUsers()
    } catch (e) {
      console.log(e)
    }
  }

  function generateBankData(data, contractData) {
    let bankData = []
    const paymentInfo = {
      name: data.fullName,
      bank_code: `${data['custom:bankCode']}`,
      branch_code: `${data['custom:bankBranch']}`,
      account_number: `${data['custom:bankAccountNumber']}`,
      amount: numeral(contractData.amount).format('10000.00'),
      account_type: 9,
      transaction_code: '35',
      value_date: format(new Date(), 'ddMyy'),
      currency_code: '00',
      originator_account_number: '1001199826',
      narrative: 'SolarPurchase'
    }
    bankData.push(paymentInfo)
    return bankData
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ marginTop: 4 }}
        {...other}
      >
        {value === index && <Box> {children} </Box>}
      </div>
    )
  }

  function ContractsTable(props) {
    const { rowData } = props
    const parentRowData = rowData
    return (
      <MaterialTable
        isLoading={isLoading}
        columns={[
          {
            title: 'Name',
            field: 'name',
            type: 'string',
            defaultSort: 'asc',
            style: { fontSize: 6 }
          },
          {
            title: 'Active',
            field: 'active',
            type: 'string',
            render: rowData => (rowData.active ? 'Yes' : 'No')
          },
          {
            title: 'Active Date',
            field: 'activeDate',
            type: 'string',
            defaultSort: 'asc',
            render: rowData =>
              rowData.active
                ? format(new Date(rowData.activeDate), 'MM/dd/yyyy')
                : 'Not Active yet'
          },
          {
            title: 'Amount',
            field: 'amount',
            type: 'string',
            defaultSort: 'asc',
            render: rowData => numeral(rowData.amount).format('0,0.00')
          },
          {
            title: 'Payment CSV',
            field: 'bankData',
            render: rowData => (
              <CSVLink
                data={generateBankData(parentRowData, rowData)}
                filename={`payment_csv_for_contract_: ${rowData.contractId}.csv`}
                enclosingCharacter={``}
              >
                Download
              </CSVLink>
            )
          },
          {
            title: 'Contract Status',
            render: rowData =>
              rowData.active ? (
                'Active'
              ) : (
                <ThemeProvider theme={theme}>
                  <ColorButton
                    variant="contained"
                    startIcon={<CheckCircle />}
                    disabled={rowData.active}
                    color="primary"
                    size="small"
                    onClick={() =>
                      activateContractAddTransaction({ parentRowData, rowData })
                    }
                  >
                    Activate
                  </ColorButton>
                </ThemeProvider>
              )
          },
          {
            title: 'Download Contract',
            field: 'downloadContract',
            render: rowData =>
              rowData.active ? (
                <ContractPdfGenerate contract={rowData} />
              ) : (
                'Not active'
              )
          }
        ]}
        data={query =>
          new Promise((resolve, reject) => {
            getContractsForUser(rowData.sub).then(result => {
              resolve({
                data: result,
                prd: rowData,
                page: 0
              })
            })
          })
        }
        title={``}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: rowData.active ? '#FFF' : '#fff4e5'
          })
        }}
      />
    )
  }

  function MaterialT(props) {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          // key={'top' + 'center'}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Client successfully updated
          </Alert>
        </Snackbar>
        <Paper className={classes.root}>
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {'Active'}
                  <Avatar
                    style={{
                      marginLeft: 4,
                      backgroundColor: green[500],
                      fontWeight: 'bold',
                      fontSize: 16,
                      width: 30,
                      height: 30
                    }}
                  >
                    {state.clients.length}
                  </Avatar>
                </div>
              }
            />
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {'Not Activated'}
                  <Avatar
                    style={{
                      marginLeft: 4,
                      backgroundColor: orange[500],
                      fontWeight: 'bold',
                      fontSize: 16,
                      width: 30,
                      height: 30
                    }}
                  >
                    {state.inActiveClients.length}
                  </Avatar>
                </div>
              }
            />
            <Tab
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {'Pending'}
                  <Avatar
                    style={{
                      marginLeft: 4,
                      backgroundColor: orange[500],
                      fontWeight: 'bold',
                      fontSize: 16,
                      width: 30,
                      height: 30
                    }}
                  >
                    {state.pendingClients.length}
                  </Avatar>
                </div>
              }
            />
          </Tabs>
        </Paper>
        <TabPanel value={activeTab} index={0}>
          <MaterialTable
            isLoading={isLoading}
            tableRef={tableRef}
            editable={{
              onRowAdd: newData => new Promise(resolve => handleSubmit(newData))
            }}
            columns={columns}
            data={[...state.clients]}
            title="Active Clients"
            options={{
              search: true,
              paging: true,
              pageSize: 10,
              rowStyle: rowData => ({
                backgroundColor: rowData['custom:isActive'] ? '#FFF' : '#fff4e5'
              })
            }}
            detailPanel={[
              {
                render: rowData => {
                  name = rowData.fullName
                  email = rowData.email
                  userName = rowData['sub']
                  return (
                    <DetailPanel
                      rowData={rowData}
                      idStatusRef={idStatusRef}
                      bankAccountName={bankAccountName}
                      bankAccountNumber={bankAccountNumber}
                      bankName={bankName}
                      bankBranch={bankBranch}
                      bankCode={bankCode}
                      bankAccountType={bankAccountType}
                      residencyStatusRef={residencyStatusRef}
                      bankStatusRef={bankStatusRef}
                      messageRef={messageRef}
                      ContractsTable={ContractsTable}
                      handleUserUpdate={handleUserUpdate}
                      handleSubmit={handleSubmit}
                      userName={userName}
                      name={name}
                      email={email}
                    />
                  )
                }
              }
            ]}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <MaterialTable
            isLoading={isLoading}
            tableRef={tableRef}
            editable={{
              onRowAdd: newData => new Promise(resolve => handleSubmit(newData))
            }}
            columns={columns}
            data={[...state.inActiveClients]}
            title="Clients not activated yet"
            options={{
              search: true,
              paging: true,
              pageSize: 10,
              rowStyle: rowData => ({
                backgroundColor: rowData['custom:isActive'] ? '#FFF' : '#fff4e5'
              })
            }}
            detailPanel={[
              {
                render: rowData => {
                  name = rowData.fullName
                  email = rowData.email
                  userName = rowData['sub']
                  return (
                    <DetailPanel
                      rowData={rowData}
                      idStatusRef={idStatusRef}
                      bankAccountName={bankAccountName}
                      bankAccountNumber={bankAccountNumber}
                      bankName={bankName}
                      bankBranch={bankBranch}
                      bankCode={bankCode}
                      bankAccountType={bankAccountType}
                      residencyStatusRef={residencyStatusRef}
                      bankStatusRef={bankStatusRef}
                      messageRef={messageRef}
                      ContractsTable={ContractsTable}
                      handleUserUpdate={handleUserUpdate}
                      handleSubmit={handleSubmit}
                      userName={userName}
                      name={name}
                      email={email}
                    />
                  )
                }
              }
            ]}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <MaterialTable
            isLoading={isLoading}
            tableRef={tableRef}
            columns={pendingColumns}
            data={[...state.pendingClients]}
            title="Pending Clients"
            options={{
              search: true,
              paging: true,
              pageSize: 10
            }}
            detailPanel={[
              {
                render: rowData => {
                  name = rowData.fullName
                  email = rowData.email
                  userName = rowData['sub']
                  return (
                    <DetailPanel
                      rowData={rowData}
                      idStatusRef={idStatusRef}
                      bankAccountName={bankAccountName}
                      bankAccountNumber={bankAccountNumber}
                      bankName={bankName}
                      bankBranch={bankBranch}
                      bankCode={bankCode}
                      bankAccountType={bankAccountType}
                      residencyStatusRef={residencyStatusRef}
                      bankStatusRef={bankStatusRef}
                      messageRef={messageRef}
                      ContractsTable={ContractsTable}
                      handleUserUpdate={handleUserUpdate}
                      handleSubmit={handleSubmit}
                      userName={userName}
                      name={name}
                      email={email}
                    />
                  )
                }
              }
            ]}
          />
        </TabPanel>
      </div>
    )
  }
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <MaterialT clients={state.clients} />
          <Divider my={6} />
        </Grid>
      </Grid>
    </div>
  )
}

function DetailPanel({
  rowData,
  idStatusRef,
  bankCode,
  bankAccountType,
  bankAccountNumber,
  bankName,
  bankBranch,
  handleUserUpdate,
  residencyStatusRef,
  bankStatusRef,
  messageRef,
  ContractsTable,
  handleSubmit,
  userName,
  name,
  email
}) {
  const classes = useStyles()

  const [numPagesId, setNumPagesId] = useState(null)
  const [pageNumberId, setPageNumberId] = useState(1)
  const [numPagesRes, setNumPagesRes] = useState(null)
  const [pageNumberRes, setPageNumberRes] = useState(1)

  function onDocumentLoadSuccessId({ numPages }) {
    setNumPagesId(numPages)
  }

  function onDocumentLoadSuccessRes({ numPages }) {
    setNumPagesRes(numPages)
  }
  return (
    <>
      <Container
        fixed
        spacing={3}
        direction="row"
        className="container"
        justifycontent="flex-start"
      >
        <Grid
          container
          spacing={3}
          style={{ marginTop: 20 }}
          justifycontent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} xl={4} lg={4} md={4}>
            <Paper elevation={3}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className={classes.faIcon}
                    />
                  }
                  title="Name & Address"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    {rowData['fullName']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['address']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['custom:city']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['custom:parishProvinceState']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['custom:country']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['custom:zipPostal']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['email']}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {rowData['phone_number']}
                  </Typography>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      className={classes.faIcon}
                    />
                  }
                  title="Confirm Docs"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent>
                  {/* <div> */}
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup row>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          name="bankStatus"
                          label="Bank Status"
                          variant="outlined"
                          defaultValue={
                            rowData['custom:bankAccountVerified'] === undefined
                              ? 'notReviewed'
                              : rowData['custom:bankAccountVerified']
                          }
                          style={{ maxWidth: 300 }}
                          select
                          inputRef={bankStatusRef}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            key={0}
                            value="notReviewed"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Not reviewed
                          </MenuItem>
                          <MenuItem
                            key={1}
                            value="bankVerified"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Bank verified
                          </MenuItem>
                          <MenuItem
                            key={2}
                            value="bankRejected"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Bank rejected
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </FormGroup>
                    <FormGroup row>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          name="idStatus"
                          label="Id Status"
                          variant="outlined"
                          defaultValue={
                            rowData['custom:idVerified'] === undefined
                              ? 'notReviewed'
                              : rowData['custom:idVerified']
                          }
                          style={{ maxWidth: 300 }}
                          select
                          inputRef={idStatusRef}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            key={0}
                            value="notReviewed"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Not reviewed
                          </MenuItem>
                          <MenuItem
                            key={1}
                            value="idVerified"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Id verified
                          </MenuItem>
                          <MenuItem
                            key={2}
                            value="idRejected"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Id rejected
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </FormGroup>
                    <FormGroup row>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <TextField
                          name="residencyStatus"
                          label="Residency Status"
                          variant="outlined"
                          defaultValue={
                            rowData['custom:residencyVerified'] === undefined
                              ? 'notReviewed'
                              : rowData['custom:residencyVerified']
                          }
                          style={{ maxWidth: 300 }}
                          select
                          inputRef={residencyStatusRef}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          <MenuItem
                            key={0}
                            value="notReviewed"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Not reviewed
                          </MenuItem>
                          <MenuItem
                            key={1}
                            value="residencyVerified"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Residency verified
                          </MenuItem>
                          <MenuItem
                            key={2}
                            value="residencyRejected"
                            style={{ padding: 5, fontSize: 16 }}
                          >
                            Residency rejected
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </FormGroup>
                    <FormGroup row>
                      <Typography variant="caption" gutterBottom>
                        If you're rejecting or need anything to be reviewed, a
                        message is required to let the customer know what item
                        needs to be corrected. In this box, please write the
                        message that will be emailed to the client. Be direct
                        and concise with the message and do not use any
                        greetings as the email template has these already setup,
                        alerting the client that there is an error with their
                        sign up.
                      </Typography>
                      <TextField
                        multiline
                        minRows={5}
                        label="Message to user about rejected item"
                        variant="outlined"
                        style={{ maxWidth: 350 }}
                        inputRef={messageRef}
                      />
                    </FormGroup>
                    <FormGroup row>
                      <Button
                        style={{ margin: [0, 'auto'] }}
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={e => handleSubmit(e, userName, name, email)}
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </FormControl>
                  {/* </div> */}
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} xl={8} lg={8} md={8}>
            <Paper elevation={3}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faPassport}
                      className={classes.faIcon}
                    />
                  }
                  title={`ID & Residency Docs`}
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ fontSize: 16, display: 'flex' }}>
                  <div style={{ textAlign: 'right' }}>
                    {rowData['custom:idUrl'] === undefined ? (
                      'No ID uploaded'
                    ) : rowData['custom:idFileType'] === 'application/pdf' ? (
                      <>
                        <Zoom>
                          <Document
                            file={rowData['custom:idUrl']}
                            onLoadSuccess={onDocumentLoadSuccessId}
                            className={classes.pdfDocument}
                          >
                            <Page pageNumber={pageNumberId} width={350} />
                          </Document>
                        </Zoom>
                        {numPagesId ? (
                          <PdfPagination
                            count={numPagesId}
                            onChange={page => setPageNumberId(page)}
                          />
                        ) : null}
                        <Link
                          href={rowData['custom:idUrl']}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download attachment
                        </Link>
                      </>
                    ) : (
                      <>
                        <Zoom zoomMargin={200}>
                          <img
                            src={rowData['custom:idUrl']}
                            alt="ID"
                            height="200"
                            width="350"
                          />
                        </Zoom>
                      </>
                    )}
                  </div>
                  <div style={{ textAlign: 'right', marginLeft: 20 }}>
                    {rowData['custom:residencyFileUrl'] === undefined ? (
                      'No residency doc uploaded'
                    ) : rowData['custom:residencyFileType'] ===
                      'application/pdf' ? (
                      <>
                        <Zoom>
                          <Document
                            file={rowData['custom:residencyFileUrl']}
                            onLoadSuccess={onDocumentLoadSuccessRes}
                            className={classes.pdfDocument}
                          >
                            <Page pageNumber={pageNumberRes} width={350} />
                          </Document>
                        </Zoom>

                        {numPagesRes ? (
                          <PdfPagination
                            count={numPagesRes}
                            onChange={page => setPageNumberRes(page)}
                          />
                        ) : null}
                        <Link
                          href={rowData['custom:residencyFileUrl']}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download attachment
                        </Link>
                      </>
                    ) : (
                      <>
                        <Zoom zoomMargin={200}>
                          <img
                            src={
                              rowData['custom:residencyFileUrl'] +
                              '-/inline/yes/'
                            }
                            alt="Bank Statement"
                            height="200"
                            width="350"
                          />
                        </Zoom>
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>
              <Card style={{ marginTop: -25 }}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faPiggyBank}
                      className={classes.faIcon}
                    />
                  }
                  title="Bank Info"
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ fontSize: 16 }}>
                  <div>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup row>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            name="bankName"
                            label="Bank Name"
                            defaultValue={rowData['custom:bankName']}
                            style={{ maxWidth: 300 }}
                            inputRef={bankName}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </FormControl>
                      </FormGroup>
                      <FormGroup row>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <TextField
                            name="bankBranch"
                            label="Bank Branch"
                            defaultValue={rowData['custom:bankBranch']}
                            style={{ maxWidth: 300 }}
                            inputRef={bankBranch}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </FormControl>
                      </FormGroup>
                      <FormGroup row>
                        <FormControl className={classes.formControl}>
                          <TextField
                            name="bankAccountNumber"
                            label="Bank Account Number"
                            defaultValue={rowData['custom:bankAccountNumber']}
                            style={{ maxWidth: 300 }}
                            inputRef={bankAccountNumber}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </FormControl>
                      </FormGroup>
                      <FormGroup row>
                        <FormControl className={classes.formControl}>
                          <TextField
                            name="bankCode"
                            label="Bank Code"
                            defaultValue={rowData['custom:bankCode']}
                            style={{ maxWidth: 300 }}
                            inputRef={bankCode}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </FormControl>
                      </FormGroup>
                      <FormGroup row>
                        <FormControl className={classes.formControl}>
                          <TextField
                            name="bankAccountType"
                            label="Bank Account Type"
                            defaultValue={rowData['custom:bankAccountType']}
                            style={{ maxWidth: 300 }}
                            inputRef={bankAccountType}
                            InputLabelProps={{
                              shrink: true
                            }}
                          ></TextField>
                        </FormControl>
                      </FormGroup>
                      <FormGroup row>
                        <Button
                          style={{ margin: [0, 'auto'] }}
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={e => handleUserUpdate(e, userName)}
                        >
                          Save
                        </Button>
                      </FormGroup>
                    </FormControl>
                  </div>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          <hr />
          <Grid item lg={12} xl={12} style={{ marginTop: 20 }}>
            <Paper elevation={3}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <FontAwesomeIcon
                      icon={faFileContract}
                      className={classes.faIcon}
                    />
                  }
                  title={`Contracts for ${rowData.fullName}`}
                  classes={{
                    title: classes.title
                  }}
                />
                <CardContent style={{ fontSize: 16 }}>
                  <ContractsTable rowData={rowData} />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
