import React from 'react'

import {
  Home,
  DollarSign,
  Heart,
  TrendingUp,
  Sun,
  Trello,
  Users,
  UserPlus
} from 'react-feather'

// Dashboards components
const Default = import('../pages/dashboards/Default')
const Rates = import('../pages/components/Rates')
const Returns = import('../pages/components/Returns')
const PreviousReturns = import('../pages/components/PreviousReturns')
const Leads = import('../pages/components/Leads')
const Clients = import('../pages/components/Clients')
const Plants = import('../pages/components/Plants')
const Banks = import('../pages/components/Banks')
const NewClient = import('../pages/components/NewClient')
const ClientAudit = import('../pages/components/ClientAudit')
const AdminUsers = import('../pages/components/AdminUsers')

const dashboardsRoutes = {
  id: 'Dash',
  path: '/',
  icon: <Trello />,
  component: Default,
  containsHome: true,
  children: null
}

const ratesRoutes = {
  id: 'Rates',
  path: '/rates',
  icon: <DollarSign />,
  component: Rates,
  children: null
}

const returnsRoutes = {
  id: 'Returns',
  // path: '/returns',
  icon: <TrendingUp />,
  component: Returns,
  children: [
    {
      path: '/returns',
      name: 'Next Return',
      component: Returns
    },
    {
      path: '/returns/completed',
      name: 'Previous Returns',
      component: PreviousReturns
    }
  ]
}

const leadsRoutes = {
  id: 'Leads',
  path: '/leads',
  icon: <Users />,
  component: Leads,
  children: null
}

const clientsRoutes = {
  id: 'Clients',
  path: '/clients',
  icon: <Heart />,
  // component: Clients,
  containsHome: false,
  children: [
    {
      path: '/clients',
      name: 'All clients',
      component: Clients
    },
    {
      path: '/clients/new',
      name: 'Add a client',
      component: NewClient
    },
    {
      path: '/client-audit',
      name: 'Client Audit',
      component: ClientAudit
    }
  ]
}

const plantRoutes = {
  id: 'Plants',
  path: '/plants',
  icon: <Sun />,
  component: Plants,
  containsHome: false
  // children: [
  //   {
  //     path: '/plants',
  //     name: 'All Plants',
  //     component: Plants
  //   }
  // {
  //   path: '/plants/data',
  //   name: 'Plant Data',
  //   component: PlantData
  // }
  //]
}

const banksRoutes = {
  id: 'Banks',
  path: '/banks',
  icon: <Home />,
  component: Banks,
  containsHome: false
}

// const transactionRoutes = {
//   id: 'Transactions',
//   path: '/transactions',
//   icon: <List />,
//   component: Transactions,
//   containsHome: false
// }

//const WithdrawalRoutes = {
//   id: 'Withdrawals',
//   path: '/withdrawals',
//   icon: <Send />,
//   component: Withdrawals,
//   containsHome: false
// }

const userRoutes = {
  id: 'Users',
  path: '/users',
  icon: <UserPlus />,
  component: AdminUsers
}

export const dashboard = [dashboardsRoutes]

export const rates = [ratesRoutes]

export const returns = [returnsRoutes]

export const leads = [leadsRoutes]

export const plantData = [plantRoutes]

export const clients = [clientsRoutes]

export const banks = [banksRoutes]

// export const transactions = [transactionRoutes]

export const users = [userRoutes]

export default [
  dashboardsRoutes,
  banksRoutes,
  clientsRoutes,
  leadsRoutes,
  plantRoutes,
  ratesRoutes,
  returnsRoutes,
  // transactionRoutes,
  userRoutes
]
