import React, { useEffect, useMemo } from 'react'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
// import Loader from 'react-loader-spinner'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { Widget } from '@uploadcare/react-widget'
import clsx from 'clsx'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Divider from '@material-ui/core/Divider'
import Slider from '@material-ui/core/Slider'
import { ThemeProvider } from '@material-ui/styles'
import numeral from 'numeral'
import { useForm, Controller } from 'react-hook-form'
import { PARISH_PROVINCE_STATE, BANKS } from './constants'
import { stepTwoValidation } from './validations'
import { FormHelperText } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formGroup: {
    marginBottom: theme.spacing(4)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inputRoot: {
    '&$disabled': {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#1956a6'
    }
  },
  disabled: {},
  textCenter: {
    textAlign: 'center'
  },
  mt: {
    marginTop: 20
  }
}))

const AmountSlider = createTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: '#52af77',
        height: 8
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
          boxShadow: 'inherit'
        }
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 14px)',
        top: -22,
        '& *': {
          background: 'transparent',
          color: '#000'
        }
      },
      track: {
        height: 8,
        borderRadius: 4
      },
      rail: {
        height: 8,
        borderRadius: 4
      }
    }
  }
})

const DEFAULT_VALUES = {
  address: '',
  city: '',
  parishProvinceState: '',
  zipPostal: '',
  country: 'Barbados',
  bankAccountType: '',
  bankAccountName: '',
  bankName: '',
  bankLocation: '',
  bankBranch: ''
}

export default function ClientBasicInfo({ handleBack, handleNext }) {
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    formState
  } = useForm({
    resolver: stepTwoValidation(),
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES
  })

  useEffect(() => {
    register({ name: 'parishProvinceState' })
  }, [register])

  const onSubmit = data => {
    console.log('onSubmit -> data', data)
  }

  const handleChangeParish = evt => {
    setValue('parishProvinceState', evt.target.value, {
      shouldDirty: true,
      shouldValidate: true
    })
  }

  const watchBankName = watch('bankName')
  const watchBankLocation = watch('bankLocation')

  const getBankLocations = useMemo(() => {
    if (watchBankName?.length) {
      const found = BANKS.find(bank => bank.name === watchBankName)
      if (found) {
        return found.locations
      }
      return []
    }
    return []
  }, [watchBankName])

  const getBankBranch = useMemo(() => {
    if (watchBankLocation?.length) {
      const found = getBankLocations.find(
        loca => loca.name === watchBankLocation
      )
      return found ? found.branch : ''
    }
    return ''
  }, [getBankLocations, watchBankLocation])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid
        container
        justifyContent="center"
        spacing={10}
        style={{
          paddingTop: 20
        }}
      >
        <Grid item xs={10} lg={8} xl={5} md={5}>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="Address"
              variant="outlined"
              name="address"
              inputRef={register}
              error={!!errors?.['address']}
              helperText={errors?.['address']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="City"
              variant="outlined"
              name="city"
              inputRef={register}
              error={!!errors?.['city']}
              helperText={errors?.['city']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              select
              fullWidth
              required
              // name="parishProvinceState"
              type="text"
              label="Parish/Province/State"
              variant="outlined"
              onChange={handleChangeParish}
              value={watch('parishProvinceState')}
              error={!!errors?.['parishProvinceState']}
              helperText={errors?.['parishProvinceState']?.message}
            >
              {PARISH_PROVINCE_STATE.map(province => (
                <MenuItem value={province} key={province}>
                  {province}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className={classes.formGroup}>
            <TextField
              fullWidth
              required
              name="zipPostal"
              variant="outlined"
              type="text"
              label="Postal/Zip Code"
              inputRef={register}
              error={!!errors?.['zipPostal']}
              helperText={errors?.['zipPostal']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              fullWidth
              required
              disabled
              name="country"
              type="text"
              label="Country"
              variant="outlined"
              inputRef={register}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  disabled: classes.disabled
                }
              }}
            />
          </div>

          <Divider className={classes.mt} />

          <Typography
            variant="h3"
            component="h1"
            style={{ color: '#1957a6', marginTop: 20, marginBottom: 20 }}
          >
            Bank Info
          </Typography>

          <div className={classes.formGroup}>
            <FormControl
              component="fieldset"
              error={!!errors?.['bankAccountType']}
            >
              <FormLabel component="legend">Account Type</FormLabel>
              <Controller
                as={
                  <RadioGroup row aria-label="bankAccountType">
                    <FormControlLabel
                      value="Checking"
                      control={<Radio />}
                      label="Checking"
                    />
                    <FormControlLabel
                      value="Savings"
                      control={<Radio />}
                      label="Savings"
                    />
                  </RadioGroup>
                }
                name="bankAccountType"
                control={control}
              />
              <FormHelperText>
                {errors?.['bankAccountType']?.message}
              </FormHelperText>
            </FormControl>
          </div>

          <div className={classes.formGroup}>
            <TextField
              fullWidth
              disabled
              required
              name="bankAccountName"
              type="text"
              label="Bank Account Holder's Name"
              variant="outlined"
              inputRef={register}
              error={!!errors?.['bankAccountName']}
              helperText={errors?.['bankAccountName']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <Controller
              as={
                <TextField
                  fullWidth
                  required
                  select
                  label="Bank Name"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {BANKS.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      value={item.name}
                      style={{ padding: 5, fontSize: 16 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="bankName"
              control={control}
              error={!!errors?.['bankName']}
              helperText={errors?.['bankName']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <Controller
              as={
                <TextField
                  label="Bank Location"
                  variant="outlined"
                  fullWidth
                  select
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  {getBankLocations.map((item, idx) => (
                    <MenuItem
                      key={idx}
                      value={item.name}
                      style={{ padding: 5, fontSize: 16 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="bankLocation"
              control={control}
              error={!!errors?.['bankLocation']}
              helperText={errors?.['bankLocation']?.message}
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              fullWidth
              required
              disabled
              name="bankBranch"
              type="text"
              label="Bank Branch"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputRef={register}
              error={!!errors?.['bankBranch']}
              helperText={errors?.['bankBranch']?.message}
              value={getBankBranch}
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              fullWidth
              required
              name="bankAccountNumber"
              type="text"
              label="Bank Account Number"
              variant="outlined"
            />
          </div>
        </Grid>

        <Grid item xs={10} lg={8} xl={5} md={5}>
          <div className={clsx(classes.formGroup, classes.textCenter)}>
            <Typography variant="h6" align="center">
              Identification Document
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              Example: Passport, Driver's License, National ID
            </Typography>
            <Widget
              publicKey="85f4e36ea46be8ad63f0"
              id="idUrl"
              previewStep="true"
              crop
              // onChange={info => updateIdUrl(info, 'identification')}
              tabs="file url gdrive gphotos dropbox evernote onedrive box"
              // value={appState.user.idUrl}
            />
          </div>

          <div className={clsx(classes.formGroup, classes.textCenter)}>
            <Typography variant="h6" align="center">
              Residency Document
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              Example: Utility Bill, Tax Bill, Bank Statement
            </Typography>
            <Widget
              publicKey="85f4e36ea46be8ad63f0"
              id="residencyFileUrl"
              previewStep="true"
              crop
              // onChange={info => updateIdUrl(info, 'residency')}
              tabs="file url gdrive gphotos dropbox evernote onedrive box"
              // value={appState.user.residencyFileUrl}
            />
          </div>

          <Divider />
          <Typography
            variant="h3"
            component="h1"
            style={{ color: '#1957a6', marginTop: 20, marginBottom: 20 }}
          >
            Select Digital Solar Panels
          </Typography>

          <Typography
            variant="body1"
            gutterBottom
            style={{ color: '#000', fontWeight: 600 }}
          >
            Selected number of panels
            {` ${6} | ${1.56} kWp`}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: '#000', fontWeight: 600 }}
          >
            Price {numeral(7020).format('$0,0')}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: '#000', fontWeight: 600 }}
          >
            Estimated Monthly Solar Credits: {numeral(62.24).format('$0,0.00')}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: '#000', fontWeight: 600 }}
          >
            Estimated Year 1 Solar Credits: {numeral(746.93).format('$0,0.00')}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ color: '#000', fontWeight: 600 }}
          >
            *Projected Return: {numeral(10.64).format('0.00%')}
          </Typography>

          <div className={classes.formGroup}>
            <ThemeProvider theme={AmountSlider}>
              <Slider
                valueLabelDisplay="off"
                defaultValue={1}
                step={1}
                min={1}
                max={20}
              />
            </ThemeProvider>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          md={12}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <div>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              size="large"
              onClick={handleBack}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 40 }}
              size="large"
              type="submit"
              disabled={!formState.isDirty}
            >
              Finish
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
