import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default function PdfPagination({
  count = 1,
  defaultPage = 1,
  onChange
}) {
  const classes = useStyles()

  const [page, setPage] = useState(defaultPage || 1)

  const onHandleChangePage = newPage => {
    setPage(newPage)
    onChange(newPage)
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="previous"
        onClick={() => onHandleChangePage(page - 1)}
        disabled={page === 1}
      >
        <KeyboardArrowLeftOutlinedIcon />
      </IconButton>
      <span>{`${page} of ${count}`}</span>
      <IconButton
        aria-label="next"
        onClick={() => onHandleChangePage(page + 1)}
        disabled={count === page}
      >
        <KeyboardArrowRightOutlinedIcon />
      </IconButton>
    </div>
  )
}
