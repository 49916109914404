const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    banksURL: 'https://jwaq2ti5g8.execute-api.us-east-1.amazonaws.com/dev',
    contractsURL: 'https://l5zqb5zlte.execute-api.us-east-1.amazonaws.com/dev',
    emailURL: 'https://c3c6u0d3ql.execute-api.us-east-1.amazonaws.com/dev',
    leadsURL: 'https://c5gdkglkn9.execute-api.us-east-1.amazonaws.com/dev',
    plantsURL: 'https://g7qaoc89ya.execute-api.us-east-1.amazonaws.com/dev',
    ratesURL: 'https://zitc4tu1i8.execute-api.us-east-1.amazonaws.com/dev',
    returnsURL: 'https://t0mxy60t6d.execute-api.us-east-1.amazonaws.com/dev',
    usersURL: 'https://mph8v0kop8.execute-api.us-east-1.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_ZCEy6Abuk',
    APP_CLIENT_ID: '1p3gvp35400mijurv6pgbeoskh',
    IDENTITY_POOL_ID: 'us-east-1:9ffdd232-ac1b-4c07-badd-5c951261acbc',
    CLIENT_USER_POOL_ID: 'us-east-1_Svmbj5SIM'
  }
}

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    banksURL: 'https://skzsqiw087.execute-api.us-east-1.amazonaws.com/prod',
    contractsURL: 'https://gsw5wvaybl.execute-api.us-east-1.amazonaws.com/prod',
    emailURL: 'https://ih0ih22rc2.execute-api.us-east-1.amazonaws.com/prod',
    leadsURL: 'https://5hld65gnj1.execute-api.us-east-1.amazonaws.com/prod',
    plantsURL: 'https://odtquubdq4.execute-api.us-east-1.amazonaws.com/prod',
    ratesURL: 'https://zdxw95ic0h.execute-api.us-east-1.amazonaws.com/prod',
    returnsURL: 'https://23kykf5qge.execute-api.us-east-1.amazonaws.com/prod',
    usersURL: 'https://sif0zn8nzg.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_YOVSfMt9p',
    APP_CLIENT_ID: '27o9q6t13apgbs9cqhkcbkkpl2',
    IDENTITY_POOL_ID: 'us-east-1:d091bfee-2c02-465c-9342-4838ed8e801d',
    CLIENT_USER_POOL_ID: 'us-east-1_Teb2DEB6l'
  }
}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

export default {
  ...config
}
