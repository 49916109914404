import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import Loader from 'react-loader-spinner'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MuiPhoneNumber from 'material-ui-phone-number'
import { useForm } from 'react-hook-form'
import { stepOneValidation } from './validations'
import { API, Auth } from 'aws-amplify'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formGroup: {
    marginBottom: theme.spacing(4)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inputRoot: {
    '&$disabled': {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#1956a6'
    }
  },
  disabled: {},
  textCenter: {
    textAlign: 'center'
  }
}))

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: ''
}

export default function StepOne({ handleNext }) {
  const classes = useStyles()
  const { register, handleSubmit, errors, formState } = useForm({
    resolver: stepOneValidation(),
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES
  })

  async function createUser(data) {
    return await API.put('users', `/users/add`, {
      body: {
        name: data.name,
        password: 'Changeme$1',
        userPoolId: Auth.userPool.userPoolId
      }
    })
  }

  const onSubmit = data => {
    createUser(data)
    handleNext()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid
        container
        justifyContent="center"
        spacing={10}
        style={{
          paddingTop: 20
        }}
      >
        <Grid item xs={10} lg={8} xl={5} md={5}>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="First Name"
              variant="outlined"
              name="firstName"
              inputRef={register}
              error={!!errors?.['firstName']}
              helperText={errors?.['firstName']?.message}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="Last Name"
              variant="outlined"
              name="lastName"
              inputRef={register}
              error={!!errors?.['lastName']}
              helperText={errors?.['lastName']?.message}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              inputRef={register}
              error={!!errors?.['email']}
              helperText={errors?.['email']?.message}
            />
          </div>
          <div className={classes.formGroup}>
            <MuiPhoneNumber
              fullWidth
              required
              disableAreaCodes
              name="phone"
              label="Phone"
              variant="outlined"
              type="phone"
              defaultCountry={'bb'}
              preferredCountries={['bb', 'ca', 'us', 'gb']}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              inputRef={register}
              error={!!errors?.['password']}
              helperText={errors?.['password']?.message}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              required
              fullWidth
              label="Confirm Password"
              variant="outlined"
              type="password"
              name="confirmPassword"
              inputRef={register}
              error={!!errors?.['confirmPassword']}
              helperText={errors?.['confirmPassword']?.message}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          md={12}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <div>
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              size="large"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 40 }}
              size="large"
              type="submit"
              disabled={!formState.isDirty}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
