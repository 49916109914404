import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Loader from 'react-loader-spinner'
import { API } from 'aws-amplify'
import MaterialTable from 'material-table'
import numeral from 'numeral'
import { CSVLink } from 'react-csv'
import Alert from '../../components/Alert'
import { parse } from 'date-fns'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}))

export default function PreviousReturns() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({
    returns: []
  })
  const [errorRes, setErrorRes] = useState({
    error: false,
    message: ''
  })

  async function loadReturns() {
    return await API.get('returns', '/returns/completed')
  }

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      setErrorRes({ error: false, message: '' })
      try {
        const returnData = await loadReturns()
        const filteredReturnData = returnData.filter(
          r => Date.parse(r.dateCompleted) > Date.parse('10/18/2020')
        )
        setState(state => ({
          ...state,
          returns: filteredReturnData
        }))
      } catch (e) {
        setErrorRes({ error: true, message: e.message })
      }
      setIsLoading(false)
    }
    onLoad()
  }, [])

  function ReturnsTable(props) {
    const { returns } = props
    return (
      <MaterialTable
        columns={[
          {
            title: 'Period',
            field: 'PK',
            type: 'string',
            render: rowData => rowData.PK.substr(5),
            defaultSort: 'asc',
            customSort: (a, b) =>
              new Date(b.PK.substr(5)) - new Date(a.PK.substr(5))
          },
          {
            title: 'Return Completed Date',
            field: 'dateCompleted',
            type: 'date',
            customSort: (a, b) =>
              new Date(a.dateCompleted).getTime() -
              new Date(b.dateCompleted).getTime()
          },
          {
            title: '# of Transactions',
            field: 'transactionCount',
            type: 'numeric'
          },
          {
            title: 'Bank CSV',
            field: 'csvData',
            render: rowData => (
              <CSVLink
                data={rowData.bankData}
                filename={`payments_for_return_period: ${rowData.PK.substr(
                  5
                )}.csv`}
              >
                Download
              </CSVLink>
            )
          },
          {
            title: 'Completed By',
            field: 'completedBy',
            type: 'string'
          }
        ]}
        data={[...returns]}
        title={`Completed returns`}
        options={{
          search: false,
          paging: false,
          exportButton: true
        }}
        detailPanel={rowData => {
          return (
            <MaterialTable
              editable={{
                isEditable: () => false
              }}
              columns={[
                {
                  title: 'User',
                  render: rowData => rowData.name
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  render: rowData => numeral(rowData.amount).format('$0.00')
                }
              ]}
              data={rowData.bankData}
              title={`Transactions for return period ${rowData.PK.substr(5)}`}
            />
          )
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} lg={12} xl={12} style={{ margin: 20 }}>
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={70}
            visible={isLoading}
          />
          {errorRes.error ? <Alert message={errorRes.message} /> : null}
          <ReturnsTable returns={state.returns} />
        </Grid>
      </Grid>
    </div>
  )
}
