import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { API } from 'aws-amplify'
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography
} from '@material-ui/core'

import { spacing } from '@material-ui/system'

import { green, red } from '@material-ui/core/colors'

import Actions from './Actions'
import BarChart from './BarChart'
import LineChart from './LineChart'
import DoughnutChart from './DoughnutChart'
import Stats from './Stats'
import Table from './Table'
import numeral from 'numeral'
import { SentimentSatisfied } from '@material-ui/icons'

const Divider = styled(MuiDivider)(spacing)

const Typography = styled(MuiTypography)(spacing)

function Default(props, { theme }) {
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState({
    contracts: []
  })

  const getTotalPayout = async () => {
    const returns = await API.get('returns', `/returns`)
    const filteredReturns = returns.filter(r => parseFloat(r.returnTotal) > 0)
    return filteredReturns.reduce(
      (prev, cur) => parseFloat(prev) + (parseFloat(cur.returnTotal) || 0),
      0
    )
  }

  const getContracts = async () => {
    const contracts = await API.get('contracts', `/contracts/all`)
    const activeContracts = contracts.filter(c => c.active === true)
    setState(state => ({ ...state, contracts: activeContracts }))
    const totalKwp = activeContracts.reduce(
      (prev, cur) => parseFloat(prev) + parseFloat(cur.kWp),
      0
    )
    setState(state => ({ ...state, totalKwp }))
    const totalPanels = activeContracts.reduce(
      (prev, cur) => parseInt(prev) + parseInt(cur.panels),
      0
    )
    setState(state => ({ ...state, totalPanels }))
    const totalClients = [...new Set(activeContracts.map(c => c.name))].length
    setState(state => ({ ...state, totalClients }))
    const totalPayout = await getTotalPayout()
    setState(state => ({ ...state, totalPayout }))
  }

  useEffect(() => {
    getContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" display="inline">
            Welcome back, {props.user}
          </Typography>
          {/* <Typography variant="body2" ml={2} display="inline">
            Monday, 29 April 2019
          </Typography> */}
        </Grid>

        <Grid item>{/* <Actions /> */}</Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total kWp"
            amount={parseFloat(state.totalKwp).toFixed(2)}
            chip="Today"
            percentageText="+26%"
            percentagecolor={green[500]}
            isLoading={!state.totalKwp}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Panels Sold"
            amount={state.totalPanels}
            chip="Annual"
            percentageText="-14%"
            percentagecolor={red[500]}
            isLoading={!state.totalPanels}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Credits Paid"
            amount={`$` + numeral(state.totalPayout).format('0,0.00')}
            chip="Monthly"
            percentageText="+18%"
            percentagecolor={green[500]}
            isLoading={!state.totalPayout}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Clients"
            amount={state.totalClients}
            chip="Yearly"
            percentageText="-9%"
            percentagecolor={red[500]}
            isLoading={!state.totalClients}
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid> 
      </Grid>*/}
    </React.Fragment>
  )
}

export default withTheme(Default)
