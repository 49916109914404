import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Amplify from 'aws-amplify'
import AWS from 'aws-sdk'
import config from './config'
import { Provider } from 'react-redux'
import store from './redux/store/index'

AWS.config.apiVersions = {
  cognitoidentityserviceprovider: '2016-04-18'
}

AWS.config.update({
  region: 'us-east-1'
})

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'contracts',
        endpoint: config.apiGateway.contractsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'email',
        endpoint: config.apiGateway.emailURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'leads',
        endpoint: config.apiGateway.leadsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'plants',
        endpoint: config.apiGateway.plantsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'rates',
        endpoint: config.apiGateway.ratesURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'returns',
        endpoint: config.apiGateway.returnsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'transactions',
        endpoint: config.apiGateway.transactionsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'withdrawals',
        endpoint: config.apiGateway.withdrawalsURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'users',
        endpoint: config.apiGateway.usersURL,
        region: config.apiGateway.REGION
      },
      {
        name: 'banks',
        endpoint: config.apiGateway.banksURL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
