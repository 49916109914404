import React, { useState, useEffect } from 'react'
import { a, API } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import config from '../../config'
import MaterialTable from 'material-table'
import numeral from 'numeral'
import MuiAlert from '@material-ui/lab/Alert'

import { Grid, Divider } from '@material-ui/core'
import 'react-medium-image-zoom/dist/styles.css'
import green from '@material-ui/core/colors/green'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 400
    }
  },
  formControl: {
    margin: theme.spacing(3)
  },
  h1: {
    fontSize: 70,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 0.5,
    marginTop: 50
  },
  h1p1: {
    fontSize: 22,
    color: '#fff',
    lineHeight: '130%',
    fontWeight: 'normal'
  },
  heading: {
    fontSize: 18,
    color: '#1957a6'
  },
  card: {
    fontSize: 28,
    fontWeight: 600,
    color: '#3d5170'
    // padding: 16
    // minHeight: 130
  },
  title: {
    fontSize: 28,
    color: '#1957a6'
  },
  accountTitle: {
    fontSize: '.40em',
    fontWeight: 800,
    color: '#3d5170'
  },
  greeting: {
    fontSize: 18,
    color: '#1957a6',
    marginBottom: '3vh'
  },
  faIcon: {
    fontSize: 36,
    color: 'Dodgerblue'
  },
  faImage: {
    fontSize: 300,
    color: 'Dodgerblue'
  },
  nextButton: {
    background: '#fbb116',
    border: '0 none',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.175)',
    borderRadius: '5px',
    // color: '#ffffff',
    cursor: 'pointer',
    display: 'inline-block',
    // fontFamily: 'ProximaNovaSemibold',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '50px',
    padding: '0 23px',
    textDecoration: 'none',
    // width: 'auto',
    minWidth: '300px',
    textAlign: 'center',
    marginTop: 20
  }
}))


export default function ClientAudit() {
  const [isLoading, setIsLoading] = useState(true)
  let poolId = config.cognito.CLIENT_USER_POOL_ID

  const [state, setState] = useState({
    clients: [],
    contracts: []
  })

  let columns = [
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc'
    },
    {
      title: 'Panels',
      field: 'dPanelsTotal',
      cellStyle: (e, rowData) => {
        if (rowData.uPanelsTotal !== rowData.cPanelsTotal) {
          return { color: 'red' }
        }
      }
      //   customSort: (a, b) => {
      //     return (
      //       parseInt(a['custom:totalPanels'] || 0) -
      //       parseInt(b['custom:totalPanels'] || 0)
      //     )
      //   }
    },
    {
      title: 'Panel Limit',
      field: 'dPanelLimit',
      cellStyle: (e, rowData) => {
        if (rowData.uPanelLimit !== rowData.cPanelLimit) {
          return { color: 'red' }
        }
      }
    },
    {
      title: 'kWp',
      field: 'dKwpTotal',
      cellStyle: (e, rowData) => {
        if (rowData.uKwpTotal !== rowData.cKwpTotal) {
          return { color: 'red' }
        }
      }
    },
    {
      title: 'Contracts',
      field: 'dContractCount',
      //   render: rowData =>
      //     numeral(rowData['custom:totalContracts']).format('0,0'),
      customSort: (a, b) => {
        return (
          parseInt(a['custom:totalContracts'] || 0) -
          parseInt(b['custom:totalContracts'] || 0)
        )
      },
      cellStyle: (e, rowData) => {
        if (rowData.uContractCount !== rowData.cContractCount) {
          return { color: 'red' }
        }
      }
    },
    {
      title: 'Credits',
      field: 'dCreditsTotal',
      cellStyle: (e, rowData) => {
        if (rowData.uCreditsTotal !== rowData.rCreditsTotal) {
          return { color: 'red' }
        }
      }
    }
  ]

  const getData = async () => {
    try {
      const active = []
      const rawUsers = await API.get('users', `/users/${poolId}`)
      rawUsers.forEach((u, idx) => {
        if (u['custom:isActive'] === 'true') {
          active.push(u)
        }
      })
      const contracts = await API.get('contracts', '/contracts/all')
      const activeContracts = contracts.filter(c => c.active)
      let userTotals = []
      activeContracts.map(c => {
        if (!userTotals.some(a => a.id === c.userId)) {
          userTotals.push({
            id: c.userId,
            name: c.name,
            dContractCount: 0,
            uContractCount: 0,
            cContractCount: 1,
            dPanelsTotal: 0,
            uPanelsTotal: 0,
            cPanelsTotal: c.panels,
            dPanelLimit: 0,
            uPanelLimit: 0,
            cPanelLimit: 20 - parseInt(c.panels),
            dKwpTotal: 0,
            uKwpTotal: 0,
            cKwpTotal: c.kWp,
            dCreditsTotal: 0,
            uCreditsTotal: 0,
            rCreditsTotal: 0
          })
        } else {
          userTotals.some(a => {
            if (a.id === c.userId) {
              a.cContractCount++
              a.cPanelsTotal += parseInt(c.panels)
              a.cPanelLimit = 20 - parseInt(a.cPanelsTotal) || 0
              a.cKwpTotal += parseFloat(c.kWp)
            }
          })
        }
      })
      active.map(u => {
        userTotals.some(a => {
          if (a.id === u.sub) {
            a.uContractCount += parseInt(u['custom:totalContracts'])
            a.dContractCount =
              a.uContractCount.toString() + ' | ' + a.cContractCount.toString()
            a.uPanelsTotal += parseInt(u['custom:totalPanels'])
            a.dPanelsTotal =
              a.uPanelsTotal.toString() + ' | ' + a.cPanelsTotal.toString()
            a.uPanelLimit += parseInt(u['custom:panelLimit'])
            a.dPanelLimit =
              a.uPanelLimit.toString() + ' | ' + a.cPanelLimit.toString()
            a.uKwpTotal += parseFloat(u['custom:totalKwp'])
            a.dKwpTotal =
              Number(a.uKwpTotal).toFixed(2) +
              ' | ' +
              Number(a.cKwpTotal).toFixed(2)
            a.uCreditsTotal += Number(
              parseFloat(u['custom:creditsTotal']).toFixed(2)
            )
          }
        })
      })
      const returns = await API.get('returns', '/returns')
      const filteredReturns = returns.filter(r => r.SK === 'Return Credits')
      filteredReturns.map(r => {
        userTotals.some(a => {
          if (a.id === r.PK.substring(4)) {
            let creditsTotal = 0
            r.returnData.map(rd => (creditsTotal += rd.amount))
            a.rCreditsTotal += Number(parseFloat(creditsTotal).toFixed(2))
            a.dCreditsTotal =
              a.uCreditsTotal.toString() + ' | ' + a.rCreditsTotal.toString()
          }
        })
      })
      setState(() => ({
        clients: userTotals,
        contracts
      }))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getData()
    // CompareData()
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function ClientList(props) {
    return (
      <MaterialTable
        isLoading={isLoading}
        columns={columns}
        data={props.clients}
        title="Client Audit"
        options={{
          search: true,
          paging: false
          //   rowStyle: rowData => ({
          //     backgroundColor: rowData['custom:isActive'] ? '#FFF' : '#fff4e5'
          //   })
        }}
      />
    )
  }
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <ClientList clients={state.clients} />
        </Grid>
      </Grid>
    </div>
  )
}
