import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(2)
  }
}))

export default function Alerts({
  message = 'This is an error alert — check it out!'
}) {
  const classes = useStyles()

  return (
    <MuiAlert severity="error" className={classes.alert}>
      {message}
    </MuiAlert>
  )
}
